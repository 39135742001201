export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {},
    sortBy: ['id'],
    sortDesc: [false],
    clientOptions: {
        filterPanelExpanded: false,
    },
}