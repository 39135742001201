<template>
  <v-chip class="ui-chip" v-bind="$attrs" v-on="$listeners" :color="backgroundColor" :text-color="textColor" label :ripple="false">
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-chip>
</template>

<script>
export default {
  name: 'UiChip',
  props: {
    status: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    backgroundColor() {
      switch (this.status) {
        case true:
          return 'green100'
        default:
          return 'gray100'
      }
    },
    textColor() {
      switch (this.status) {
        case true:
          return 'green800'
        default:
          return 'gray900'
      }
    },
  },
}
</script>

<style lang="scss">
.ui-chip {
  height: 22px!important;
  border-radius: 6px!important;
  .v-chip__content {
    font-weight: 500;
    font-size: 12px;
  }
}
</style>
