export default [
  {
    path: '/countries',
    name: 'pageCountriesList',
    meta: { title: 'country.listTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/countries'),
  },
  {
    path: '/countries/create',
    name: 'pageCountriesCreate',
    meta: { title: 'country.createFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/countries/create'),
  },
  {
    path: '/countries/:id/edit',
    name: 'pageCountriesEdit',
    meta: { title: 'country.editFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/countries/id/edit'),
  },
]
