import fileRepository from "@/repository/generatedRepository/fileRepository";

const state = {
}

const getters = {
}

const actions = {
  loadFile (context, payload) {
    return fileRepository.post(payload)
  },
  loadFileWithConfig (context, payload) {
    const { formData, config } = payload
    return fileRepository.post(formData, config)
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
