/*
  Danger! Do not remove any comments from this file, generator will be broken!
 */

export const locale = {
  // __GENERATED_TRANSLATIONS__
   spendsParsingLog: {
     listTitle: 'Источники траффика',
     editFormTitle: 'Редактирование источника траффика',
     createFormTitle: 'Создание источника траффика',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Источники траффика',
     table: {
       id: 'ID',
       partner: '',
       group: '',
       fileId: '',
       date: '',
       parse: '',
       status: '',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       active: 'Статус',
     },
  },
   
   partnersSpendsTable: {
     listTitle: 'Траты партнеров',
     editFormTitle: 'Редактирование трат партнеров',
     createFormTitle: 'Создание трат партнеров',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Источники траффика',
     table: {
       advertiser: 'Рекламодатель',
       stream: 'Поток',
       offer: 'Оффер',
       site_id: 'Site ID',
       country: 'Страна',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       active: 'Статус',
     },
  },
   
   trafficSources: {
     listTitle: 'Источники траффика',
     editFormTitle: 'Редактирование источника траффика',
     createFormTitle: 'Создание источника траффика',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Источники траффика',
     table: {
       id: 'ID',
       title: 'Название',
       active: 'Статус',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       active: 'Статус',
     },
  },
   
   landings: {
     listTitle: 'Лендинги',
     editFormTitle: 'Редактирование лендинга',
     createFormTitle: 'Создание лендинга',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Лендинги',
     table: {
       id: 'ID',
       title: 'Название',
       subId: 'Sub ID',
       active: 'Статус',
       actions: 'Действия',
     },
     field: {
       title: 'Название*',
       value: 'Значение',
       subId: 'Sub ID',
       active: 'Статус',
     },
  },
   
   budgetReport: {
     listTitle: 'Отчет по бюджету',
     editFormTitle: 'Редактирование постбека',
     createFormTitle: 'Создание постбека',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Отчет по бюджету',
     table: {
       title: 'Рекламодатель',
       budget: 'Запланированный бюджет',
       actual_budget: 'Актуальный бюджет',
       free_budget: 'Свободный бюджет',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   partnerReport: {
     listTitle: 'Отчет по партнерам',
     editFormTitle: 'Редактирование постбека',
     createFormTitle: 'Создание постбека',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Отчет по партнерам',
     table: {
       title: 'Партнеры и группы',
       total: 'Новые депозиты',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   roles: {
     listTitle: 'Роли',
     editFormTitle: 'Редактирование роли',
     createFormTitle: 'Создание роли',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Роли',
     table: {
       id: 'ID',
       title: 'Название',
       active: 'Статус',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   partnerGroups: {
     listTitle: 'Партнерские группы',
     editFormTitle: 'Редактирование партнерской группы',
     createFormTitle: 'Создание партнерской группы',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Партнерские группы',
     table: {
       id: 'ID',
       title: 'Название',
       leaders: 'Тимлидеры',
       offers: 'Офферы',
       partners: 'Партнеры',
       managers: 'Менеджеры',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       leaders: 'Тимлидеры',
       active: 'Статус',
     },
  },
   
   generalReport: {
     listTitle: 'Общий отчет',
     editFormTitle: 'Редактирование',
     createFormTitle: 'Создание',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Общий отчет',
     table: {
       advertiser: 'Рекламодатель',
       partner: 'Партнер',
       country: 'Страна и Media ID',
       offer: 'Оффер',
       siteId: 'Site ID',
       subId: 'Sub ID',
       clickId: 'Click ID',
       registrations: 'Кол-во новых регистраций',
       deposits: 'Кол-во новых депозитов',
       ratio: 'Рейтинг деп/рег',
       amount_newDeposits: 'Сумма новых депозитов',
       amount_bets: 'Сумма ставок',
       avg_bet: 'Средняя ставка',
       avg_check: 'Средний чек',
       income: 'Доход компании',
       spend: 'Потрачено',
       roi: 'ROI',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   playersReports: {
     listTitle: 'Отчеты игроков',
     editFormTitle: 'Отчет игрока',
     createFormTitle: 'Создание игрока',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Отчеты игроков',
     table: {
       id: 'ID',
       date: 'Дата и время загрузки',
       period: 'Период',
       deposits: 'Сумма депозита (всего {sum})',
       bets: 'Сумма ставок (всего {sum})',
       income: 'Доход компании (всего {sum})',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   players: {
     listTitle: 'Игроки',
     editFormTitle: 'Игрок',
     createFormTitle: 'Создание игрока',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Игроки',
     table: {
       id: 'Внутренний ID игрока',
       outerId: 'Внешний ID игрока',
       clickId: 'Сlick ID',
       registrationDate: 'Дата и время создания',
       siteId: 'Site ID',
       partner: 'Партнер',
       advertiser: 'Рекламодатель',
       mediaId: 'Страна & Media ID',
       geoCountry: 'IP',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   currencies: {
     listTitle: 'Валюты',
     editFormTitle: 'Редактирование валюты',
     createFormTitle: 'Создание валюты',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Валюты',
     table: {
       id: 'ID',
       title: 'Название',
       value: 'Значение в теге',
       active: 'Статус',
       actions: 'Действия',
     },
     field: {
       title: 'Название*',
       value: 'Значение в теге (ссылка рекл.)',
       active: 'Статус',
     },
  },

   postbacks: {
     listTitle: 'Постбеки',
     editFormTitle: 'Редактирование постбека',
     createFormTitle: 'Создание постбека',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Постбеки',
     table: {
       id: 'ID',
       createdAt: 'Дата & Время',
       advertiser: 'Рекламодатель',
       clickId: 'Click ID',
       partnerId: 'Partner ID',
       siteId: 'Site ID',
       sum: 'Сумма деп.',
       transaction: 'Транзакция',
       other: 'Другое',
       eventType: 'Тип события',
       eventValue: 'Значение события',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Сумма*',
       active: 'Статус',
     },
  },
   
   events: {
     listTitle: 'События',
     editFormTitle: 'Редактирование события',
     createFormTitle: 'Создание события',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'События',
     table: {
       id: 'ID',
       name: 'Имя',
       partner: 'Партнер',
       placeholder: 'Имя в плейсхолдере',
       active: 'Статус',
       actions: 'Действия',
     },
     field: {
       name: 'Название*',
       placeholder: 'Имя в плейсхолдере',
       active: 'Статус',
     },
  },
   
   partnersSpends: {
     listTitle: 'Расходы партнеров',
     editFormTitle: 'Редактирование расходов партнера',
     createFormTitle: 'Создание расходов партнера',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Расходы партнеров',
     table: {
       id: 'ID',
       name: 'Имя',
       partner: 'Партнер',
       siteId: 'Site ID',
       country: 'Страна & Media ID',
       date: 'Дата',
       sum: 'Сумма (итого 500)',
       actions: 'Действия',
     },
     field: {
       partner: 'Партнер*',
       offerPartner: 'Site ID*',
       sum: 'Сумма*',
       date: 'Дата*'
     },
  },
   
   users: {
     listTitle: 'Пользователи',
     editFormTitle: 'Редактирование пользователя',
     createFormTitle: 'Создание пользователя',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Пользователи',
     table: {
       id: 'ID',
       name: 'Имя',
       email: 'Email',
       enabled: 'Статус',
       actions: 'Действия',
     },
     field: {
       name: 'Название для скриптов',
       email: 'Email*',
       password: 'Пароль*',
       enabled: 'Статус',
     },
  },
   
   clicks: {
     listTitle: 'Клики',
     editFormTitle: 'Редактирование сущности',
     createFormTitle: 'Создание сущности',
     formFieldsBlockTitle: 'Поля для данных',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Клики',
     table: {
       id: 'ID',
       clickId: 'Click ID',
       createdAt: 'Дата & время',
       offerTitle: 'Оффер',
       partnerTitle: 'Партнер',
       advertiserTitle: 'Рекламодатель',
       partnerLink: 'Partner Link',
       advertiserLink: 'Advertiser Link',
       country: 'Страна & MediaID',
       actions: 'Действия',
     },
     field: {
       idOneC: 'ID 1c',
       name: 'Имя',
       countries: 'Страны',
       countries2: 'Страны 2',
       scriptName: 'Название для скриптов',
     },
  },
   
   offer: {
     listTitle: 'Офферы',
     editFormTitle: 'Оффер',
     createFormTitle: 'Оффер',
     formFieldsBlockTitle: 'Основное',
     formTwoFieldsBlockTitle: 'Партнеры',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     menuItemName: 'Офферы',
     table: {
       id: 'ID',
       title: 'Название',
       advertiserTitle: 'Рекламодатель',
       countryTitle: 'Страна & Media ID',
       paymentModelTitle: 'Модель оплаты',
       cost: 'Стоимость',
       productTitle: 'Продукт',
       actions : 'Действия',
     },
     field: {
       title: 'Название*',
       advertiser: 'Рекламодатель',
       country: 'Страна & Media ID',
       paymentModel: 'Модель оплаты',
       cost: 'Стоимость',
       product: 'Продукт',
       partner: 'Партнер*',
       siteId: 'Site ID',
       tag: 'Тег',
       postback: 'Постбек',
       landing: 'Лендинг',
       partnerLink: 'Partner Link',
       advertiserLink: 'Advertiser Link',
       status: 'Статус'
     },
  },

  partners: {
    listTitle: 'Партнеры',
    editFormTitle: 'Редактирование партнера',
    createFormTitle: 'Создание партнера',
    formFieldsBlockTitle: 'Основное',
    formTwoFieldsBlockTitle: 'Офферы',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    menuItemName: 'Партнеры',
    activity: 'Активность',
    table: {
      id: 'ID',
      title: 'Имя',
      offerPartners: 'Офферы',
      active: 'Статус',
      actions: 'Действия',
    },
    field: {
      title: 'Название*',
      status: 'Статус',
      offer: 'Оффер*',
      siteId: 'Site ID',
      tag: 'Тег',
      postback: 'Постбек',
      landing: 'Лендинг',
      partnerLink: 'Partner Link',
      advertiserLink: 'Advertiser Link',
    },
  },

  country: {
    listTitle: 'Страны',
    editFormTitle: 'Редактирование страны',
    createFormTitle: 'Создание страны',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    menuItemName: 'Страны',
    active: 'Активна',
    inactive: 'Неактивна',
    activity: 'Статус',
    table: {
      id: 'ID',
      title: 'Название',
      slug: 'Код',
      active: 'Статус',
      actions: 'Действия',
    },
    field: {
      title: 'Название*',
      slug: 'Код',
      status: 'Статус',
    },
    select: {},
  },

  paymentModels: {
    listTitle: 'Модели оплаты',
    editFormTitle: 'Редактирование модели оплаты',
    createFormTitle: 'Создание  модели оплаты',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    menuItemName: 'Модели оплаты',
    table: {
      id: 'ID',
      title: 'Название',
      cost: 'Стоимость',
      active: 'Статус',
      actions: 'Действия',
    },
    field: {
      title: 'Название*',
      cost: 'Стоимость',
      status: 'Статус',
    },
  },

  advertisers: {
    listTitle: 'Рекламодатели',
    editFormTitle: 'Редактирование рекламодателя',
    createFormTitle: 'Создание рекламодателя',
    formFieldsBlockTitle: 'Основное',
    formFieldBlockCountries: 'Страны',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    menuItemName: 'Рекламодатели',
    table: {
      id: 'ID',
      title: 'Название',
      domain: 'Домен',
      active: 'Статус',
      actions: 'Действия',
    },
    field: {
      title: 'Название*',
      domain: 'Домен',
      status: 'Статус',
      mediaId: 'MediaID',
    },
  },

  products: {
    listTitle: 'Продукты',
    editFormTitle: 'Редактирование продукта',
    createFormTitle: 'Создание продукта',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    menuItemName: 'Продукты',
    table: {
      id: 'ID',
      title: 'Название',
      active: 'Статус',
      actions: 'Действия',
    },
    field: {
      title: 'Название*',
      status: 'Статус',
    },
  },

  filters: {
    activity: 'Активность',
    partners: 'Партнеры',
    advertisers: 'Рекламодатели',
    country: 'Страна'
  },

  settings: {
    settingsTitle: 'Настройки',
    settingsGeneral: 'Основное',
    menuItemName: 'Settings',
    field: {
      domainName: 'Имя домена',
      postBackUrl: 'URL постбека'
    },
    btn: {
      save: 'Сохранить',
    },
  },

  base: {
    save: 'Сохранить',
    cancel: 'Отменить',
    remove: 'Удалить',
    delete: 'Убрать',
    create: 'Создать',
    areYouSureToDelete: 'Вы действительно хотите удалить?',
    areYouSureToDeactivate: 'Подтвердите деактивацию',
    areYouSureToActivate: 'Подтвердите активацию',
    yes: 'Да',
    no: 'Нет',
    add: 'Добавить',
    edit: 'Редактировать',
    editing: 'Редактирование',
    time: 'Время',
    back: 'Назад',
    filters: 'Фильтры',
    apply: 'Применить',
    selectAll: 'Выбрать все',
    clearAll: 'Очистить все',
    deactivate: 'Деактивировать',
    activate: 'Активировать',
    listStatusActive: 'Активный',
    listStatusInactive: 'Неактивный',
    accepted: 'Принято',
    processing: 'В обработке',
    incorrect: 'Неправильный',
    approved: 'Одобренный',
    rejected: 'Отклоненный',
    search: 'Поиск',
    noData: 'Нет данных',
    all: 'Все',
    allCount: 'Все ({count})',
    onlyChosen: 'Только выбранные',
    attention: 'Внимание',
    fill: 'Заполнить',
    validationError: 'Ошибка валидации',
    validationErrorServer: 'Ошибка сервера',
    deliveryServiceAndWarehouseCitiesValidationError:
      'Заполните либо удалите набор полей в блоке "Служба доставки и склад"',
    noMoreItems: 'Весь список загружен',
    noElementsFound: 'Не найдено элементов',
    import: 'Импорт',
    resetFilters: 'Сбросить фильтры',
    createdAt: 'Дата создания',
    createdBy: 'Кто создал',
    actions: 'Действия',
    city: 'Город',
    district: 'Район',
    region: 'Область',
    country: 'Страна',
    today: 'Сегодня',
    yesterday: 'Вчера',
    currentMonth: 'Текущий месяц',
    noDataAvailable: 'Данные недоступны',
    startTypingText: 'Начните вводить текст',
    change: 'Изменить',
    day: 'Дней',
    hour: 'Часов',
    minute: 'Минут',
    toTheBegining: 'В начало',
    warning: 'Предупреждение',
    choosePeriod: 'Выбрать период',
    selectPeriod: 'Выбрать период',
    filtersActive: 'Активные',
    filtersDeactivated: 'Деактивированные',
    filtersAll: 'Все',
    selectFields: 'Выбрать колонки',
    open: 'Свернуть',
    close: 'Развернуть',
    close2: 'Закрыть',
    reset: 'Сбросить',
    operator: 'Оператор',
    notOperator: 'Не оператор',
    export: 'Экспорт',
    images: 'Изображение',
    product: 'Продукт',
    selected: 'Выбрано',
    items: 'элементов',
    onPage: 'На странице',
    paginationOnPage: 'На странице',
    paginationFrom: 'из',
    history: 'История',
    field: 'Поле',
    rub: 'Рубль',
    uah: 'Гривна',
    mdl: 'Молдавский лей',
    success: 'Успешно',
    comments: 'Комментарии',
    choose: 'Выбрать',
    value: 'Значение',
    role: 'Должность',
    group: 'Группа',
    month: 'Месяц',
    niche: 'Ниша',
    total: 'Всего',
    from: 'из',
    mobileView: 'Для корректной работы системы воспользуйтесь десктопной версией',
    goToMain: 'На главную',
    showNumber: 'Показать номер',
    failedToLoad: 'Не удалось загрузить',
    failedToLoadDescription: 'Ошибка во время загрузки страницы. Попробуйте перезагрузить страницу',
    limitText: 'и еще {count}',
    addSelectedOptions: 'Добавить выбранные опции',
    removeSelectedOptions: 'Исключить выбранные опции',
    ok: 'Хорошо',
    selectDate: 'Выбрать дату',
    presets: {
      today: 'Сегодня',
      todayAndYesterday: 'Сегодня и вчера',
      yesterday: 'Вчера',
      currentWeek: 'Текущая неделя',
      previousWeek: 'Прошлая неделя',
      currentMonth: 'Текущий месяц',
      previousMonth: 'Прошлый месяц',
      currentYear: 'Текущий год',
    },
    beWaiting: 'Ожидайте',
    dataLoading: 'Данные обрабатываются',
  },
  call: {
    order: 'Заявка',
    offer: 'Оффер',
    call: 'Звонок',
    voice: 'Голос',
    callbackList: 'Список коллбеков',
  },
  dashboard: {
    main: 'Дашборд',
    operator: 'Дашборд оператора',
    applications: 'Заявки',
    operatorOrders: 'Заявки оператора',
    loadFromFile: 'Загрузить из файла',
    calls: 'Звонки',
    controlList: 'КЛН',
    klnOperator: 'КЛН оператора',
    questioning: 'Анкетирование',
    normsAndGrades: 'Нормы и грейды',
    normsAndGradesSub: {
      operatorGrades: 'Грейды операторов',
      offerNorms: 'Нормы по офферам',
    },
    missedCalls: 'Пропущенные вызовы',
    tickets: 'Тикеты',
    potentialTrash: 'Потенциальный треш',
    potentialTrashSub: {
      notProcessed: 'Не обработанные',
      processed: 'Обработанные',
      disputable: 'Спорные',
    },
    callCampaigns: 'Кампании прозвона',
    smsCampaigns: 'СМС кампании',
    stats: 'Статистика',
    statsSub: {
      operatorsTimeTracking: 'Учет времени оператора',
      incorrectStats: 'Статистика некорректных',
      fraudSpam: 'Фрод/Спам',
      statsCC: 'Статистика КЦ',
      statsRepeat: 'Статистика ПП',
      checkDistribution: 'Распределение чеков',
      statsKLN: 'Статистика КЛН',
      approveSpeed: 'Скорость апрува',
      statsRejected: 'Статистика отклоненных',
      statsCancelled: 'Статистика отмененных',
    },
    logs: 'Логи',
    logsSub: {
      loginLogs: 'Лог логинов',
      orderLogs: 'Лог заявок',
    },
    reportsExport: 'Экспорт отчетов',
    settings: 'Настройки',
    courierDelivery: 'Курьерская доставка',
    pointOfIssue: 'Пункт выдачи',
    post: 'Аналитика служб доставки',
    troubleDelivery: 'Проблемная доставка',
    deliveryServiceAnalytic: 'Аналитика служб доставки',
    financialReport: 'Финансовый отчет',
    dynamic: 'Динамика',
    averageBills: 'Средние чеки',
    ransomByDay: 'Выкуп по дням',
    dynamicRansomByDay: 'Динамика выкупа по дням',
    hungRansom: 'Зависший выкуп',
    buybackDynamics: 'Динамика выкупа по дням',
    effOfAdditProjects: 'Эф. доп. проектов',
    finDepLog: 'Лог фин. отдела',
    ccPrice: 'Стоимость КЦ',
    ccPriceSub: {
      operatorSalary: 'ЗП операторов',
      prepaidOper1: 'Аванс 1 операторов',
      prepaidOper2: 'Аванс 2 операторов',
      operatorVacations: 'Отпуски операторов',
      teamLeadSalary: 'ЗП тимлидов',
      prepaidTeamLead1: 'Аванс 1 тимлидов',
      prepaidTeamLead2: 'Аванс 2 тимлидов',
    },
    notificationsPrice: 'Стоимость уведомлений',
    logisticPrice: 'Стоимость логистики',
    logisticPriceSub: {
      rpSalary: 'ЗП РП',
      officeSalary: 'ЗП офис',
      courierSalary: 'ЗП курьеры',
      hubHelpersSalary: 'ЗП помощники хаба',
    },
    comissionsAndTaxes: 'Комиссии и налоги',
    websPayouts: 'Выплаты вебам',
    legalPersonsStats: 'Статистика по юр. лицам',
  },
  uploadArea: {
    maxSize: 'Максимальный размер файла {maxSize} MB',
    moveYourFileOr: 'Перетащите ваш файл или',
    upload: 'загрузите',
    limit: 'Максимальное количество файлов - {limit} которое можно загрузить',
    invalidType: 'Неверный формат файла для загрузки',
  },
  lang: {
    ru: 'Русский',
    en: 'Английский',
  },
  authLogin: {
    username: 'Email',
    password: 'Пароль',
    loginIn: 'Войти',
    invalidCredentials: 'Неправильный email или пароль!',
    forgetPassword: 'Забыли пароль?',
    welcome: 'Добро пожаловать!',
  },
  authRestore: {
    email: 'Email',
    forgetPassword: 'Забыли пароль?',
    authRestoreDesc: 'Введите email и получите ссылку для восстановления:',
    send: 'Отправить',
    checkYourEmail: 'Проверьте почту',
    sentDesc: 'Мы отправили ссылку для восстановления пароля на',
    isNotFounded: 'Email не соответствует ни одной учетной записи. Попробуйте еще раз!',
  },
  authRecoveryPassword: {
    createNewPassword: 'Создайте пароль',
    create: 'Создать',
    first: 'Новый пароль',
    second: 'Повторите',
    titleOfDemands: 'Ваш пароль должен состоять из:',
    lengthDemand: 'минимум 8 символов',
    capitalDemand: 'одну заглавную букву',
    lowercaseDemand: 'одну строчную буквы',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    example: 'Пример',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Сгенерировать пароль',
    sameField: 'Пароли должны совпадать',
  },
  userGroup: {
    name: 'Название',
    typeGroup: 'Тип группы',
    newTypeGroup: 'Новый тип групп',
    main: 'Основные',
    groupOfUsers: 'Группы пользователей',
    listOfUsers: 'Список пользователей',
    operatorRoom: 'Операторская',
    newUserGroup: 'Новая группа',
    actions: 'Действия',
    countUsers: 'Кол-во пользователей',
    manager: 'Тим лидер/Руководитеь',
    users: 'Пользователи',
    user: 'Пользователь',
    show: 'Показать',
    groups: 'Группы',
    addUserType: 'Добавить тип группы',
    itemsTypeShow: {
      all: 'Все ({count})',
      onlyChosen: 'Только выбранные',
    },
    createUserGroup: 'Группа пользователей',
    editUserGroup: 'Группа пользователей',
    active: 'Активность',
    onBreakCount: 'Макс. количество на перерыве',
    saleType: 'Направление продаж',
    operator: 'Операторская',
  },
  userGroupType: {
    name: 'Название',
  },
  user: {
    users: 'Пользователи',
    newUser: 'Новый пользователь',
    fullName: 'Имя',
    email: 'Email',
    actions: 'Действия',
    name: 'Имя',
    lastName: 'Фамилия',
    main: 'Основные',
    password: 'Пароль',
    languages: 'Языки',
    countries: 'Страны',
    groups: 'Группы',
    groupTypes: 'Типы групп',
    offer: 'Оффер',
    dataAccess: 'Доступ к данным',
    role: 'Должность',
    permissions: 'Права',
    createUser: 'Пользователь',
    editUser: 'Пользователь',
    setUpAccessForRoleDesc: 'Настройка шаблона доступов для роли',
    operator: 'Оператор',
    workStartDate: 'Дата трудоустройства',
    bonusCurrency: 'Валюта для расчета бонусов',
    offers: 'Офферы',
    isOperator: 'Оператор',
    grade: 'Грейд',
    cardNumber: 'Осн. карта для выплат',
    additionalCardNumber: 'Доп. карта для выплат',
    salaryType: 'Роль для расчета ЗП',
    actionsSuccess: 'Пользователи изменены',
    activateSuccess: 'Пользователей активировано: {count}',
    deactivateSuccess: 'Пользователей деактивировано: {count} ',
  },
  userRole: {
    userRoles: 'Должности',
    newUserRole: 'Новая должность',
    name: 'Название',
    actions: 'Действия',
    roleAccess: 'Доступы для роли',
    settingAccessRole: 'Настройка шаблона доступов для роли',
    createUserRole: 'Должность',
    editUserRole: 'Должность',
  },
  offerGroup: {
    name: 'Название',
    status: 'Статус',
    main: 'Основные',
    offer: 'Оффер',
    offers: 'Офферы',
    groupOfOffers: 'Группы офферов',
    newOffer: 'Новая группа',
    actions: 'Действия',
    createOfferGroup: 'Группа офферов',
    editOfferGroup: 'Группа офферов',
  },
  error: {
    invalid: '{name} не является допустимым',
    required: '{name} обязательное поле',
    requiredIf: '{name} обязательное поле',
    email: 'Неверный формат Email',
    maxLength: 'Максимальная длинна символов {length}',
    minLength: 'Минимальная длинна символов {length}',
    maxValue: 'Максимальное значение: {value}',
    minValue: 'Минимальная значение: {value}',
    onlyNumbers: 'Вводить допустимо только цифры',
    onlyIntegerNumbers: 'Вводить допустимо только целые числа',
    onlyWords: 'Вводить допустимо только буквы',
    allow: 'Ваш пароль должен состоять из: ',
    sameField: 'Пароли должны совпадать',
    regexPassword: 'Неверный формат пароля',
    url: 'Неверный формат URL',
    dateFromLess: 'Дата "c" должна быть меньше чем дата "по"',
    dateToLess: 'Дата "по" должна быть больше чем дата "c"',
    commaSpaceRule: 'Начало и конец поля не должны содержать пробел или запятую',
    lengthDemand: 'минимум 8 символов',
    capitalDemand: 'одну заглавную букву',
    lowercaseDemand: 'одну строчную буквы',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    latinDemand: 'Пароль содержит отлько латинские буквы',
    invalidValue: 'Неправильное значение',
    notBeEmpty: 'Значение не должно быть пустым',
    error403Title: 'Ой, произошла ошибка...',
    error403Desc: 'Страница не существует или у вас нет прав доступа к ней.',
    error404Title: 'Ой, произошла ошибка...',
    error404Desc: 'Страница не существует или у вас нет прав доступа к ней.',
    emailExist: 'Такая почта уже есть в системе',
  },

  page: {
    settingsDashboard: 'Настройки',
    callCentreDashboard: 'Дашборд оператора',
    pageSettingsCountry: 'Страны',
    pageLanguageList: 'Языки',

    login: 'Авторизация',
    restore: 'Забыли пароль',
    recoveryPassword: 'Восстановление пароля',

    pageMain: 'Главная',

    pageFinanceList: 'Финансовые настройки',
    pageFinanceCreate: 'Финансовые настройки',
    pageFinanceEdit: 'Финансовые настройки',
    pageFinanceDashboard: 'Фин. настройки для стран',

    pageUserList: 'Пользователи',
    pageUserCreate: 'Пользователь',
    pageUserEdit: 'Пользователь',

    pageUserGroupList: 'Группы пользователей',
    pageUserGroupCreate: 'Группа пользователей',
    pageUserGroupEdit: 'Группа пользователей',

    pageProductList: 'Продукты',
    pageProductCreate: 'Продукт',
    pageProductEdit: 'Продукт',

    pageProductTypeList: 'Типы продуктов',
    pageProductTypeCreate: 'Тип продукта',
    pageProductTypeEdit: 'Тип продукта',
    pageProductTypeDashboard: 'Тип продукта',

    pageOffersList: 'Офферы',
    pageOffersCreate: 'Оффер',
    pageOffersEdit: 'Оффер',

    pageOfferGroupList: 'Группы офферов',
    pageOfferGroupCreate: 'Группа офферов',
    pageOfferGroupEdit: 'Группа офферов',

    pageOfferNicheList: 'Ниши офферов',
    pageOfferNicheCreate: 'Ниша оффера',
    pageOfferNicheEdit: 'Ниша оффера',
    pageOfferNormsList: 'Нормы по офферам',

    pageUserRoleList: 'Должности',
    pageUserRoleCreate: 'Должность',
    pageUserRoleEdit: 'Должность',
    pageUserRoleDashboard: 'Должности и доступы',

    pageLeadList: 'Лиды',
    pageLeadCreate: 'Лид',
    pageLeadEdit: 'Лиды',

    pageRejectReasonList: 'Причины статусов',
    pageRejectReasonCreate: 'Причина статусов',
    pageRejectReasonEdit: 'Причина статусов',

    pageOrderList: 'Заявки',
    pageOrderCreate: 'Заявка',
    pageOrderEdit: 'Заявка',

    pageOrderOperatorList: 'Заявки оператора',
    pageOrderOperatorCreate: 'Заявка оператора',
    pageOrderOperatorEdit: 'Заявка оператора',

    pageOrderImport: 'Загрузить из файла',

    pageOrderTagList: 'Теги к заявке',
    pageOrderTagCreate: 'Тег к заявке',
    pageOrderTagEdit: 'Тег к заявке',
    pageOrderTagDashboard: 'Теги к заявкам',

    pageQuestionnaireKnl: 'Анкета КЛН',
    pageQuestionnaireKnlEdit: 'Анкета оценивания КЛН',

    pageKlnAssessmentList: 'Оценки КЛН',
    pageKlnAssessmentCreate: 'КЛН по заявке',
    pageKlnAssessmentEdit: 'КЛН по заявке',

    pageKlnStatistic: 'Статистика КЛН',

    pageKlnOperatorAssessmentList: 'КЛН оператора',
    pageKlnOperatorAssessmentCreate: 'КЛН по заявке',
    pageKlnOperatorAssessmentEdit: 'КЛН по заявке',

    pageCityList: 'Населенные пункты',
    pageCityCreate: 'Населенный пункт',
    pageCityEdit: 'Населенный пункт',

    pageProductGiftList: 'Каталоги',
    pageProductGiftCreate: 'Каталог',
    pageProductGiftEdit: 'Каталог',

    pageProductSetsList: 'Наборы продуктов',
    pageProductSetsCreate: 'Наборы продуктов',
    pageProductSetsEdit: 'Наборы продуктов',

    pageGroupTypesList: 'Типы групп пользователей',
    pageGroupTypesCreate: 'Типы групп пользователей',
    pageGroupTypesEdit: 'Типы групп пользователей',
    pageGroupTypesDashboard: 'Типы групп',

    pageSalesScriptList: 'Скрипты',
    pageSalesScriptCreate: 'Скрипт',
    pageSalesScriptEdit: 'Скрипт',

    pageSalesScriptTemplateList: 'Шаблоны разделов скриптов',
    pageSalesScriptTemplateCreate: 'Шаблон',
    pageSalesScriptTemplateEdit: 'Шаблон',

    pagePhoneCodesList: 'Телефонные коды',
    pagePhoneCodesCreate: 'Телефонный код',
    pagePhoneCodesEdit: 'Телефонный код',

    pageDeliveryPointsList: 'Точки доставки',
    pageDeliveryPointsCreate: 'Точка доставки',
    pageDeliveryPointsEdit: 'Точка доставки',
    pageDeliveryPointsImport: 'Импорт точек доставки',

    pageDeliveryIntervalList: 'Интервалы доставки',
    pageDeliveryIntervalCreate: 'Интервал доставки',
    pageDeliveryIntervalEdit: 'Интервал доставки',

    pageLandingList: 'Лендинги',
    pageLandingCreate: 'Лендинг',
    pageLandingEdit: 'Лендинг',

    PageDeliveryWeekendList: 'Выходные дни',
    PageDeliveryWeekendCreate: 'Выходной день',
    PageDeliveryWeekendEdit: 'Выходной день',

    pageStorages: 'Склады',
    pageSuppliers: 'Поставщики',
    pageDeliveryService: 'Службы доставки',
    pageDeliveryServiceCreate: 'Служба доставки',
    pageDeliveryServiceEdit: 'Служба доставки',

    pageStopWordsList: 'Стоп-слова',

    pageOrganization: 'Юридические лица',

    pageBlackPhoneList: 'Черный список',
    pageBlackPhoneCreate: 'Черный список',
    pageBlackPhoneEdit: 'Черный список',
    pageTicketsList: 'Тикеты',
    pageTicketCreate: 'Тикет',
    pageTicketEdit: 'Тикет',

    pageDeliveryWarehouseList: 'Склады',
    pageDeliveryWarehouseCreate: 'Склад',
    pageDeliveryWarehouseEdit: 'Склад',

    pageRegionList: 'Области',
    pageRegionCreate: 'Область',
    pageRegionEdit: 'Область',

    pageOrderFraudList: 'Статистика Некорректных',

    pageWorkTimeList: 'Учет времени',
    pageWorkTimeMeta: 'Учет времени операторов',
    pageLimitTimeEdit: 'Лимиты времени оператора',

    pageLogAuthList: 'Логи логинов/логаутов',
    pageLogOrderList: 'Логи заявок',

    pageQuestionnairesList: 'Анкеты',
    pageQuestionnairesCreate: 'Анкета',
    pageQuestionnairesEdit: 'Анкета',

    pageQuestionnairesOrderList: 'Анкетирование',
    pageLegalPersonsStats: 'Статистика по юр. лицам',

    pageSalaryBonusList: 'Матрица для осн. ЗП КЦ',
    pageSalaryBonusDashboard: 'Матрица для основной ЗП КЦ',
    pageSalaryBonusImport: 'Импорт данных для ЗП КЦ',
    // заглушки пока что
    pageLogs: 'Логи',
    pageTagsToOrders: 'Теги к Заявкам',
    pageQuestionnaires: 'Анкеты',

    pageOrderTrash: 'Необработанный треш',
    pageOrderTrashChecked: 'Обработанный треш',
    pageOrderTrashDispute: 'Спорный треш',

    pageOperatorGrades: 'Грейды операторов',

    main: 'Основные',
    general: 'Общее',
    callCentre: 'Колл-центр',
    logistics: 'Логистика',
    finance: 'Финансы',

    pageCampaign: 'Кампании прозвона',
    pageCampaignCreate: 'Кампания прозвона',
    pageCampaignCreateManual: 'Кампания прозвона ручная',
    pageCampaignEdit: 'Кампания прозвона',

    pageCityImport: 'Импорт населенных пунктов',

    pageCurrencyConversion: 'Конвертация валют',
    pageCurrencyConversionDashboard: 'Курс валют',
    pageCurrencies: 'Валюты',

    pageSmsTemplate: 'СМС шаблоны',
    pageSmsTemplateCreate: 'Шаблон СМС',
    pageSmsTemplateEdit: 'Шаблон СМС',

    pageDistrictList: 'Районы',
    pageDistrictCreate: 'Район',
    pageDistrictEdit: 'Район',

    exportReports: 'Экспорт отчетов',
    exportReportsList: 'Список отчетов',

    pageUserPenaltyList: 'Штрафы операторов',
    pageUserPenaltyCreate: 'Штраф',
    pageUserPenaltyEdit: 'Штраф',

    pageManagerDashboard: 'Дашборд',
    pageLogisticsManagerDashboard: 'Дашборд менеджмента',
    pageCallCentreManagerDashboard: 'Дашборд менеджмента',

    pageSmsCampaign: 'СМС кампании',
    pageSmsCampaignManual: 'СМС кампания ручная',
    pageSmsCampaignAuto: 'СМС кампания автоматическая',

    pageDelivery: 'Доставка',
    pageDeliveryCourier: 'Курьерская доставка',
    pageDeliveryPoints: 'Пункт выдачи',
    pageDeliveryPost: 'Почта',

    pageSalesModelList: 'Модель продаж',
    pageSalesModelDashboard: 'Модели продаж',

    pageCostCC: 'Стоимость КЦ',
    pagePrepayment: 'Аванс 1 операторов',
    pagePrepaymentTwo: 'Аванс 2 операторов',

    pagePrepaymentTeamLead: 'Аванс 1 тим лидов',
    pagePrepaymentTeamLeadTwo: 'Аванс 2 тим лидов',

    pageUserSalary: 'ЗП операторов',

    pageEfficiencyOfAdditionalProjects: 'Эффективность доп проектов',

    pageWebAnalytica: 'Выплаты вебам',

    pagePaymentHoldList: 'Удержания',
    pagePaymentHoldCreate: 'Удержание',
    pagePaymentHoldEdit: 'Удержание',

    pagePaymentProfitList: 'Начисления',
    pagePaymentProfitCreate: 'Начисление',
    pagePaymentProfitEdit: 'Начисление',

    pageExcludedOffersForSDList: 'Исключенные офферы для СД',
    pageExcludedOffersForSDCreate: 'Оффер для СД',
    pageExcludedOffersForSDEdit: 'Оффер для СД',

    pageNotFound: '404',

    pageError403: 'Ошибка доступа',
    pageError404: 'Страница не найдена',

    pageHangingRansom: 'Зависший выкуп',

    pageDistrictImport: 'Импорт районов',
    pageRegionImport: 'Импорт областей',

    pageAverageCheck: 'Средние чеки',

    pageDistributionCheck: 'Распределение чеков',

    pageBuybackDynamics: 'Динамика выкупа по дням',

    pageRejectedStatistics: 'Статистика по отклоненным заявкам',

    pageCallController: 'Звонки',

    pageDeliveryServiceAnalytic: 'Аналитика служб доставки',
  },
  languages: {
    languages: 'Языки',
    status: 'Статус',
    country: 'Страна',
    code: 'Код',
    rowsOn: 'На странице',
    name: 'Название',
  },
  productType: {
    main: 'Основные',
    name: 'Название',
    description: 'Описание',
    addField: 'Добавить поле',
    createProductType: 'Тип продукта',
    composition: 'Состав',
    contraindications: 'Противопаказания',
    editProductType: 'Тип продукта',
    groupOfProducts: 'Типы продуктов',
    newProduct: 'Новый тип',
    actions: 'Действия',
  },
  product: {
    main: 'Основные',
    pictures: 'Изображения',
    products: 'Продукты',
    newProduct: 'Новый продукт',
    actions: 'Действия',
    idOneC: 'ID 1C',
    updateData: 'Обновить данные',
    name: 'Название',
    type: 'Тип продукта',
    alternativeName: 'Альтернативное название',
    typeOneC: 'Тип продукта 1С',
    typeCallCenter: 'Тип продукта КЦ',
    offers: 'Офферы',
    scriptName: 'Название для скриптов',
    addPicture: 'Добавить изображение',
    general: 'Общее',
    additional: 'Дополнительно',
    createProduct: 'Продукт',
    editProduct: 'Продукт',
    invalidProductOneC: 'Указанный ID не найден.',
    productTypeOneCBad: 'БАД',
    productTypeOneCNormal: 'нормальный',
    activity: 'Активность',
    hideEmptyFields: 'Скрыть',
    showEmptyFields: 'Раскрыть',
    invalidFields: 'У вас не заполнены обязательные поля во вкладке “Общее”.',
  },
  lead: {
    newLead: 'Новый лид',
    actions: 'Действия',
    leads: 'Лиды',
    name: 'Имя',
    lastName: 'Фамилия',
    patronymic: 'Отчество',
    gender: 'Пол',
    fullName: 'ФИО',
    age: 'Возраст',
    phone: 'Телефон',
    male: 'Мужской',
    female: 'Женский',
    clientData: 'Данные клиента',
    createLead: 'Обзор лида',
    editLead: 'Редактировать лида',
  },
  dialogImageCropper: {
    addPicture: 'Добавить фото',
    cancel: 'Отменить',
    save: 'Сохранить',
    crop: 'Обрезать',
    name: 'Название',
    addImg: 'Добавить изображение',
  },
  offerNiche: {
    name: 'Название',
    status: 'Статус',
    main: 'Основные',
    nicheOfOffers: 'Ниши офферов',
    newNiche: 'Синхронизировать с 1С',
    actions: 'Действия',
    createOfferNiche: 'Создать нишу оффера',
    editOfferNiche: 'Редактировать нишу оффера',
    offer: 'Оффер',
    offers: 'Офферы',
  },
  order: {
    name: 'Название',
    orders: 'Заявки',
    newOrder: 'Новая заявка',
    createOrder: 'Заявка',
    editOrder: 'Редактировать заявку',
    shippingInfo: 'Данные доставки',
    dataClient: 'Данные клиента',
    openingCard: 'Открытие карточки',
    country: 'Страна',
    age: 'Возраст',
    birthdayDate: 'Дата рождения',
    countries: 'Страны',
    region: 'Область',
    regions: 'Области',
    district: 'Район',
    city: 'Населенный пункт',
    street: 'Улица',
    phone: 'Телефон',
    createdAt: 'Дата заявки',
    lastSet: 'Последний набор',
    lastCall: 'Последний звонок',
    date: 'Дата',
    productsInOrder: 'Продукты в составе заказа',
    countryOrLanguage: 'Страна/язык',
    trackingCode: 'Штрихкод',
    statusCall: 'Статус дозвона',
    calls: 'Звонков',
    comments: 'Комментарий',
    groups: 'Группы',
    reason: 'Причина',
    house: 'Дом',
    web: 'Веб',
    webs: 'Вебы',
    kln: 'КЛН',
    block: 'Корпус',
    client: 'Клиент',
    address: 'Адрес',
    other: 'Другое',
    delivery: 'Доставка',
    orderPrice: 'Цена',
    productCount: 'шт',
    orderCar: 'Корзина заказа',
    apartment: 'Квартира',
    postalCode: 'Почтовый индекс / Zip',
    deliveryType: 'Способ доставки',
    deliveryTypes: 'Способы доставки',
    prepayment: 'Предоплата',
    paymentByTerminal: 'Оплата терминалом',
    deliveryService: 'Служба доставки',
    deliveryServices: 'Службы доставки',
    deliveryPoint: 'Точка доставки',
    deliveryDate: 'Дата доставки',
    deliveryTime: 'Время доставки',
    deliveryTimeFrom: 'Время доставки с',
    deliveryTimeTo: 'Время доставки по',
    deliveryInterval: 'Интервал доставки',
    postalAddress: 'Почтовое отделение',
    deliveryIncluded: 'Доставка включена',
    firstName: 'Имя',
    lastName: 'Фамилия',
    patronymic: 'Отчество',
    status: 'Статус',
    statuses: 'Статусы',
    rejectReason: 'Причина отмены',
    script: 'Cкрипт',
    products: 'Продукты',
    product: 'Продукт',
    history: 'История',
    questionnaires: 'Анкеты',
    comment: 'Поле для комментариев',
    dateTimeCreated: 'Дата и время создания',
    sourceOrder: 'Источник заявки',
    regionOfClient: 'Регион клиента',
    responsibleOperator: 'Ответственный оператор',
    tags: 'Теги',
    userGroups: 'Группы пользователей',
    offer: 'Оффер',
    offers: 'Офферы',
    offerGroups: 'Группы офферов',
    offerNiches: 'Ниши офферов',
    niches: 'Ниши',
    rejectReasons: 'Причины статусов',
    rejectReasonCanceledRC: 'Отмена ПП',
    callStatus: 'Статус дозвона',
    callStatuses: 'Статусы дозвона',
    activity: 'Активность',
    landing: 'Лендинг',
    landings: 'Лендинги',
    operator: 'Оператор',
    operators: 'Операторы',
    number: 'Номер',
    phoneList: 'Список номеров',
    ordersByNumber: 'Заказы по номеру',
    noOrdersByNumber: 'Нет заказов',
    typeOrOperator: 'Вид/оператор',
    type: 'Вид',
    general: 'Общий',
    additional: 'Дополнительно',
    tracking: 'Трекинг',
    createTicket: 'Создать тикет по клиенту',
    createReclamation: 'Создать рекламацию',
    createTicketByClient: 'Создать тикет по клиенту',
    historyOfOrder: 'История по заявке',
    noLogOrders: 'Нет истории',
    was: 'Было',
    became: 'Стало',
    system: 'Система',
    orderStatus: {
      accepted: 'Принята',
      approved: 'Апрув',
      delivered: 'Доставлена',
      no_ransom: 'Без выкупа',
      awaiting_ransom: 'Ожидает выкупа',
      canceled: 'Отменено',
      incorrect: 'Некорректный',
      callback: 'Колбэк',
      shipped: 'Отправленный',
      trash: 'Треш',
      processing: 'Обработка',
      cancel_after_approve: 'Отменен после апрува',
      paid: 'Оплачено',
    },
    orderList: 'Состав заказа',
    orderBundle: 'Состав набора',
    sum: 'Сумма',
    allSum: 'Сумма (всего)',
    orderWillBeDeliveredWithin: 'Заказ будет доставлен вам в течение {min}-{max} дней',
    male: 'Муж',
    female: 'Жен',
    callbackDate: 'Дата',
    listNumbers: 'Список номеров',
    callToLead: 'Вызов лида',
    callInProgress: 'Идет звонок',
    shouldCall: 'Выполнить вызов лида?',
    abounentIsNotAvailable: 'Абонент недоступен',
    abounentIsNotAvailableDescription: 'На данный момент абонент занят или недоступен...',
    callAgain: 'Перезвонить',
    attention: 'Внимание',
    mediaDeviceError: 'Вы не дали доступ к устройствам ввода и вывода аудио в настройках браузера.',
    mediaConnectStep1: 'Нажмите иконку слева от браузерной строки',
    mediaConnectStep2: 'В появившимся меню нажмите «Сбросить доступы»',
    mediaConnectStep3: 'Перезагрузите страницу браузера',
    noOktellConnection: 'Нет соединения с сервером Oktell',
    sendMessage: 'Отправить СМС',
    actions: {
      actions: 'Действия',
      changeStatus: 'Сменить статус на',
      addTags: 'Добавить теги',
      removeTags: 'Удалить теги',
      removeProduct: 'Удалить продукт из заказа',
      statusReason: 'Причина статуса',
      callback: 'Назначить колбек на',
      approveByName: 'Апрув от имени',
    },
    sourceCPA: 'СРА',
    sourceAddProject: 'Доп. проекты',
    sourceIncome: 'Входящая',
    sourceUser: 'Ручное создание',
    actionsSuccess: 'Действия применены к выбранным заявкам',
    ticketsCreated: 'Было создано {count} тикета(ов)',
    forPackaging: 'На упаковку',
    filterByDate: 'Фильтр по дате',
    hoursFromApprove: 'Более N-часов',
    warehouses: 'Склады для отправки',
    dateFilterType: {
      createdAt: 'По заходу',
      approvedAt: 'По апруву',
      shippedAt: 'По отправке (дата последней отправки)',
      paidAt: 'По оплате',
    },
    firstApprovedAt: 'Дата апрува',
    shippedDate: 'Дата отправки заказа',
    packerTask: 'Задание упаковщику',
  },
  rejectReason: {
    rejectReasons: 'Причины статусов',
    newRejectReason: 'Новая причина',
    name: 'Название',
    actions: 'Действия',
    createRejectReason: 'Причина отмены',
    editRejectReason: 'Причина отмены',
    editStatusReason: 'Причина статусов',
    main: 'Основные',
    trashMain: 'Треш',
    toOperator: 'Причину указывает оператор',
    toOperatorTableHead: 'Указывает оператор',
    explanation: 'Разъяснение',
    description: 'Разъяснение',
    textForCpa: 'Текст для СРА',
    applicableStatuses: 'Применима к статусам',
    correspondsSystem: 'Соответсвует системной',
    correspondsSystemReason: 'Соответствует системной причине "некорректный"',
    incorrect: 'Некорректный',
    canceled: 'Отмена',
    trash: 'Потенциальный треш',
    trashForm: 'Треш',
    callback: 'Колбэк',
    in_blacklist: 'Номер в черном списке',
    invalid_phone: 'Не валидный номер',
    stop_word: 'Стоп-слова в заявке',
    duplicate: 'Дубликат',
  },
  productGift: {
    createProductGift: 'Каталог',
    editProductGift: 'Каталог',
    productGifts: 'Каталоги',
    active: 'Активность',
    newProductGift: 'Новый каталог',
    catalog: 'Каталог',
    actions: 'Действия',
    main: 'Основные',
    name: 'Название',
    country: 'Страна',
    product: 'Продукт',
    settings: 'Настройки',
    offers: 'Офферы',
    storages: 'Склады',
    offerGroups: 'Группы офферов',
    offerExclusion: 'Исключенные офферы',
    addToMail: 'Почтовые заказы включены',
    mailOrders: 'Почтовые заказы',
    creator: 'Кто создал',
    creationDate: 'Дата создания',
    activeProductGift: 'Активные каталоги',
    productGiftInset: 'Вкладывать',
    productGiftNotInset: 'Не вкладывать',
    productGiftAll: 'Все',
  },
  phoneCodes: {
    codes: 'Телефонные коды',
    newCode: 'Новый код',
    code: 'Код',
    minNumber: 'Начало диапазона',
    maxNumber: 'Конец диапазона',
    timezone: 'Часовой пояс',
    time: 'Время',
    countryCode: 'Код страны',
    regionCode: 'Код региона',
    region: 'Название области',
    operator: 'Оператор связи',
    actions: 'Действия',
    phoneCode: 'Телефонный код',
    name: 'Название',
    internalCode: 'Внутренний код',
    timezoneName: 'Название часового пояса',
    regionDistrict: 'Регион/область',
    country: 'Страна',
    editPhoneCodes: 'Телефонный код',
  },
  city: {
    city: 'Населенные пункты',
    newCity: 'Новый пункт',
    createCity: 'Населенный пункт',
    main: 'Основные',
    create: 'Населеный пункт',
    country: 'Страна',
    region: 'Область',
    district: 'Район',
    deliveryService: 'Служба доставки',
    deliveryType: 'Тип Доставки',
    terminal: 'Терминал',
    locationId: 'Location ID',
    actions: 'Действия',
    editCity: 'Населенный пункт',
    deliveryServiceAndStorage: 'Служба доставки и склад',
    warehouse: 'Склад',
    refId: 'ref_id',
    availableTerminal: 'Наличие терминала',
    terminalPresent: 'Есть терминал',
    terminalAbsent: 'Нет терминала',
    terminalAll: 'Все',
    loadFromFile: 'Загрузить из файла',
    actionsModal: {
      actionType: 'Действие',
      addDeliveryService: 'Подключить СД',
      removeDeliveryService: 'Отключить СД',
      deliveryServices: 'Службы доставки',
      warehouses: 'Склады для подключаемой СД',
    },
    attention: 'Внимание',
    actionsError: 'Для нас.пункта и службы доставки может быть указан только один склад отправки.',
    actionsSuccess: 'Действия применены к выбранным населенным пунктам',
  },
  salesScript: {
    scripts: 'Скрипты',
    newScript: 'Новый скрипт',
    name: 'Название',
    general: 'Общее',
    script: 'Скрипт',
    preview: 'Предпросмотр',
    priceFormation: 'Ценообразование',
    exercisesCount: 'Кол-во уп. в курсе',
    coursePrice: 'Цены курсов',
    main: 'Основные',
    language: 'Язык',
    countries: 'Страны',
    offers: 'Офферы',
    offer: 'Оффер',
    version: 'Версия',
    sections: 'Разделы',
    salesType: 'Тип продаж',
    userGroups: 'Группы операторов',
    userGroupsDesc:
      'Выберите группу операторов для тестирования скрипта. Убедитесь, что выбранная группа операторов также добавлена в настройках телефонии для выбранных офферов.',
    operatorsDesc:
      'Выберите конкретных операторов для тестирования скрипта. Убедитесь, что выбранные операторы также добавлены в настройках телефонии для выбранных офферов.',
    salesModel: 'Модель продаж',
    users: 'Операторы',
    actions: 'Действия',
    createScript: 'Скрипт',
    editScript: 'Скрипт',
    sectionName: 'Имя',
    paragraphName: 'Имя',
    plainText: 'Обычный текст',
    addParagraph: 'Добавить параграф',
    addSelection: 'Добавить раздел',
    paragraphInSection: 'Параграфы в разделе',
    textOfParagraph: 'Текст параграфа',
    moves: 'Переходы',
    addGroup: 'Добавить группу',
    create: 'Создать',
    template: 'Шаблон',
    stylesTitle: 'Стили',
    clearStyles: 'Очистить стили',
    colorText: 'Цвет текста',
    macroses: 'Макросы',
    chooseStyleOfText: 'Выберите стиль текста',
    hideFromSection: 'Скрыть из оглавления',
    createFromTemplate: 'Создать из шаблона',
    saveSectionLikeTemplate: 'Сохранить раздел как шаблон',
    invalidFields: 'У вас не заполнены обязательные поля во вкладке “Общее”.',
    modalDescRemovingParagraph:
      'На параграф есть переходы из других параграфов. Вы уверены, что хотите удалить выбранный параграф?',
    saveSectionAsTemplate: 'Сохранить раздел как шаблон',
    active: 'Активность',
    requestHelpTeamLeader: 'Запросить помощь тимлидера',
    templateFromSection: {
      name: 'Имя шаблона раздела',
      description: 'Описание',
    },
    transition: {
      transitionGroupName: 'Название группы переходов',
      nameOfBtn: 'Название кнопки',
      paragraphAfterMoving: 'Параграф после перехода',
      colorBtn: 'Цвет кнопки',
      type: {
        info: 'информация',
        success: 'позитив',
        warning: 'предупреждение',
        danger: 'негатив',
      },
    },
    styles: {
      name: 'Название',
      tooltip: 'Подсказка',
      editableStyles: 'Редактируемые стили',
      exampleText: 'Съешь ещё этих мягких французских булок, да выпей же чаю...',
    },
    macros: {
      name: 'Название',
      macroses: 'Макросы',
      modalDescRemoving:
        'Удаленный макрос продолжит отображаться в тех скриптах, где он был использован.',
    },
    offerList: {
      offerList: 'Список офферов',
      groups: 'Группы офферов',
      niches: 'Ниши',
      show: 'Показать',
      offer: 'Оффер',
      niche: 'Ниша',
    },
  },
  financeSettings: {
    finance: 'Финансовые настройки',
    addFinance: 'Финансовые настройки',
    editFinance: 'Финансовые настройки',
    main: 'Основные',
    country: 'Страна',
    region: 'Область',
    currency: 'Валюта',
    timezone: 'Временная зона по умолчанию',
    postalService: 'Служба доставки по умолчанию',
    timezoneForm: 'Временная зона',
    deliveryCost: 'Стоимость доставки',
    actions: 'Действия',
    length: '10',
  },
  orderTag: {
    name: 'Название',
    main: 'Основные',
    actions: 'Действия',
    createOrderTag: 'Тег к заявке',
    editOrderTag: 'Тег к заявке',
    newTag: 'Новый тег',
    tags: 'Теги к заявке',
  },
  salesScriptTemplate: {
    scriptSectionTemplates: 'Шаблоны разделов скриптов',
    newScriptTemplate: 'Новый шаблон',
    name: 'Название',
    actions: 'Действия',
    createScriptTemplate: 'Шаблон',
    editScriptTemplate: 'Шаблон',
    sections: 'Разделы',
    paragraphsInSection: 'Параграфы в разделе',
    description: 'Описание',
    textOfParagraph: 'Текст параграфа',
    moves: 'Переходы',
    addGroup: 'Добавить группу',
    preview: 'Предпросмотр',
    active: 'Активность',
  },
  landing: {
    landing: 'Лендинги',
    addLanding: 'Добавить лендинг',
    removeLanding: 'Добавить лендинг',
    main: 'Основные',
    name: 'Название',
    url: 'Ссылка',
    offer: 'Оффер',
    landingEdit: 'Лендинг',
    landingCreate: 'Лендинг',
    forToday: 'За сегодня',
    forWeek: 'За неделю',
    forMonth: 'За Месяц',
    actions: 'Действия',
  },
  deliveryPoint: {
    created: 'Создал',
    lastEdited: 'Последний отредактировал',
    deliveryPoints: 'Точки доставки',
    deliveryPoint: 'Точка доставки',
    deliveryPointEdit: 'Точка доставки',
    addDeliveryPoint: 'Добавить',
    name: 'Название',
    deliveryType: 'Тип доставки',
    deliveryService: 'Служба доставки',
    terminal: 'Терминал',
    terminalPayment: 'Наличие терминала',
    city: 'Населенный пункт',
    region: 'Область',
    district: 'Район',
    country: 'Страна',
    active: 'Активность',
    locationId: 'Location ID',
    actions: 'Действия',
    main: 'Основные',
    keyIntegration: 'Ключ  интеграции',
    deliveryServiceAndStorage: 'Служба доставки и склад',
    service: 'Сервис',
    storage: 'Склад',
    loadFromFile: 'Загрузить из файла',
    minDay: 'Минимум дней',
    maxDay: 'Максимум дней',
    address: 'Адрес',
    phoneNumber: 'Телефон',
    comment: 'Комментарий',
    workTime: 'Время работы',
    status: 'Статус',
    activePoint: 'Активная',
    deactivatedPoint: 'Деактивированная',
    paymentByTerminal: 'Оплата терминалом',
    addTerminal: 'Подключить',
    removeTerminal: 'Отключить',
    actionsSuccess: 'Действия применены к выбранным точкам доставки',
  },
  orderImport: {
    loadFromFile: 'Загрузить из файла',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    addFile: 'Добавить файл',
    preview: 'Предпросмотр',
    importData: 'Импортировать данные',
    status: 'Статус',
    offer: 'Офер',
    country: 'Страна',
    language: 'Язык',
    tags: 'Теги',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    field: {
      age: 'Возраст',
      apartment: 'Номер квартиры',
      birthdayDate: 'Дата рождения',
      city: 'Город',
      errors: 'Ошибки',
      gender: 'Пол',
      house: 'Номер дома',
      lastName: 'Фамилия',
      name: 'Имя',
      orderPrice: 'Цена заказа',
      patronymic: 'Отчество',
      phone: 'Телефон',
      postalCode: 'Почтовый индекс',
      product: 'Продукт',
      street: 'Улица',
      trackingCode: 'Трек номер',
    },
  },
  deliveryInterval: {
    deliveryIntervals: 'Интервалы доставки',
    deliveryInterval: 'Интервал доставки',
    main: 'Основные',
    deliveryService: 'Служба доставки',
    city: 'Населенный пункт',
    deliveryPoint: 'Точка доставки',
    from: 'Интервал с',
    to: 'Интервал по',
    boundaryTime: 'Граничное время',
    deliveryPeriodMax: 'Максимально дней',
    deliveryPeriodMin: 'Минимально дней',
    deliveryPeriodMaxShort: 'Макс. дней',
    deliveryPeriodMinShort: 'Мин. дней',
    intervals: 'Интервалы',
    availableDeliveryDays: 'Доступные дни доставки',
    nameDeliveryService: 'Название СД',
  },
  stopWords: {
    stopWords: 'Стоп-слова',
    main: 'Основные',
    description: 'Описание',
    alertDescription: 'Перечислите стоп слова и/или фразы в текстовом поле через запятую',
  },
  deliveryWeekend: {
    deliveryWeekends: 'Выходные дни',
    deliveryWeekend: 'Выходной день',
    addDeliveryWeekend: 'Добавить выходной день',
    deliveryWeekendEdit: 'Выходной день',
    name: 'Название',
    actions: 'Действия',
    main: 'Основные',
    country: 'Страна',
    region: 'Область',
    district: 'Район',
    city: 'Населенный пункт',
    dateFrom: 'Дата с',
    dateTo: 'Дата по',
    deliveryServices: 'Служба доставки',
    active: 'Активность',
  },
  deliveryType: {
    courier: 'Курьер',
    courier_interval: 'Курьер (интервал)',
    post: 'Почта',
    pvz: 'ПВЗ',
  },
  deliveryServices: {
    nameColumn: 'Название',
    countriesColumn: 'Страны',
    countryColumn: 'Страна',
    regionColumn: 'Регион',
    cityColumn: 'Населенный пункт',
    warehousesColumn: 'Склады',
    warehouseColumn: 'Склад',
    deliveryService: 'Добавить склад',
    createDeliveryServicePageTitle: 'Служба доставки',
    editDeliveryServicePageTitle: 'Служба доставки',
    cityFilterTitle: 'Населенный пункт',
    warehouseFilterTitle: 'Склад',
    createEditFormTitle: 'Основные',
    idOneC: 'ID 1C',
    sync1C: 'Синхронизировать',
    name: 'Название',
    deliveryType: 'Способ доставки',
    description: 'Описание',
    relatedCities: 'Населенные пункты',
    dayForPayment: 'Дней для оплаты',
    updateDataMetaship: 'Обновлять точки доставки из Metaship?',
    yes: 'Да',
    no: 'Нет',
    metashipDSCode: 'Metaship deliveryService code',
    syncWithMetaship: 'Синхр. с Metaship',
  },
  campaign: {
    title: 'Кампании прозвона',
    titleItem: 'Кампания прозвона',
    newCampaign: 'Добавить',
    addManual: 'Выбрать заявки вручную',
    filter: {
      offers: 'Офферы',
      salesType: 'Тип продаж',
      activity: 'Активность',
      countries: 'Страны',
    },
    table: {
      id: 'ID',
      name: 'Название',
      countryLang: 'Страна/Язык',
      typeSell: 'Тип продаж',
      date: 'Дата создания',
      nameCreator: 'Кто создал',
      status: 'Cтатус кампании',
      actions: 'Действия',
    },
    form: {
      title: 'Настройки кампании',
      titleEdit: 'Основное',
      titleEditList: 'Очередь на прозвон',
      name: 'Название',
      salesType: 'Тип продаж',
      country: 'Страна',
      language: 'Язык',
      questionnaire: 'Анкета',
      activity: 'Статус кампании',
      offers: 'Офферы',
      products: 'Продукты',
      startDate: 'Дата присвоения статуса с',
      endDate: 'Дата  присвоения статуса по',
      timeAfter: 'Время с момента присвоения статуса',
      timeAfterSkip: 'Не набирать, если прошло более',
      dayDelay: 'Дней задержки на 1 упаковку осн. товара',
      dayDelayNext: 'Шаг (дней) для каждой последующей упаковки',
      productQuantity: 'Количество упаковок',
      statuses: 'Статусы',
      absentOffer: 'Отсутсвует связанный оффер:',
      absentScript: 'Отсутсвует связанный скрипт:',
      scripts: 'Офферы',
    },
    queueList: {
      id: 'ID Заявки',
      status: 'Статус звонка',
      create: 'Созданная заявка',
      date: 'Дата/время',
    },
    manual: {
      title: 'Кампания прозвона ручная',
      form: {
        title: 'Основные',
        name: 'Название',
        offer: 'Оффер кампании',
        dateStart: 'Дата старта кампании',
        activity: 'Статус кампании',
      },
      filterOrder: {
        title: 'Фильтр заявки',
        salesType: 'Тип продаж',
        niche: 'Ниша',
        tags: 'Теги',
        country: 'Страна',
        offers: 'Офферы',
        language: 'Язык',
        offerGroups: 'Группы офферов',
        status: 'Статус',
        products: 'Продукты',
      },
      tableOrder: {
        id: 'Заказ №',
        niche: 'Ниша',
        offer: 'Оффер',
        operator: 'Оператор',
        operatorGroup: 'Группа оператора',
      },
    },
  },
  productSets: {
    productSets: 'Наборы продуктов',
    createProductSets: 'Набор продуктов',
    editProductSets: 'Набор продуктов',
    newProductSets: 'Добавить',
    placeholderInput: 'Название',
    placeholderAlternativeTitle: 'Альтернативное название',
    nameForScripts: 'Название для скриптов',
  },
  blackPhone: {
    blackPhone: 'Черный список',
    newBlackPhone: 'Добавить',
    dateOfCreation: 'Дата создания',
    phone: 'Номер',
    author: 'Кто добавил',
    addToBlacklist: 'Добавить в черный список',
    phoneNumber: 'Номер телефона',
  },
  groupTypes: {
    groupTypes: 'Типы групп пользователей',
    newGroupTypes: 'Добавить',
    name: 'Название',
    groups: 'Группы',
    groupTypesAdd: 'Добавить тип группы',
    groupTypesCancel: 'Добавить',
  },
  tickets: {
    creatBy: 'Создатель:',
    createData: 'Создан:',
    tickets: 'Тикеты',
    ticket: 'Тикет',
    created: 'Создан',
    whoCreate: 'Кто создал',
    ticketCreatedBy: 'Создатель тикета',
    type: 'Тип тикета',
    application: 'Заявка',
    priority: 'Приоритет',
    status: 'Статус тикета',
    group: 'Группа',
    accountable: 'Ответственный',
    accountableGroupUsers: 'Ответственная группа пользователей',
    accountableGroup: 'Ответственная группа',
    accountableUser: 'Ответственный пользователь',
    main: 'Основное',
    ticketType: 'Тип тикета',
    deadLine: 'Дата дедлайна',
    executionPeriod: 'Срок выполнения (часы)',
    problemDescription: 'Описание проблемы',
    commentAccountableUser: 'Комментарий исполнителя',
    dateCreation: 'Дата создания',
    titleCreate: 'Создать тикеты (рекламации)',
    operatorAbbreviation: 'оп',
    noOperator: 'оператор не закреплен',
  },
  ticketType: {
    reclamation: 'Рекламация',
    client_request: 'Обращение клиента',
  },
  ticketPriority: {
    high: 'Высокий',
    medium: 'Средний',
    low: 'Низкий',
  },
  questionnaireKnl: {
    title: 'Анкета КЛН',
    crete: 'Добавить',
    shortName: 'Сокращенное название',
    addItem: 'Добавить пункт',
    itemName: 'Название пункта',
    name: 'Название',
    score: 'Баллы',
    evaluationOptions: 'Варианти оценки',
    actions: 'Действия',
    create: 'Создать',
    sections: 'Разделы',
    evaluationPoint: 'Пункты оценивания',
    settingsItems: 'Настройка пунктов оценивания',
    fullName: 'Полное название',
    numberOfSection: 'Порядковый номер раздела',
    evaluatingNameByValue: {
      yes: 'Дa',
      no: 'Нет',
      partial: 'Частично',
      dont_consider: 'Не учитывать',
    },
  },
  deliveryWarehouse: {
    warehouses: 'Склады',
    warehouse: 'Склад',
    name: 'Название',
    location: 'Расположение',
    regionDelivery: 'Доставка в регионы',
    deliveryService: 'Службы доставки',
    actions: 'Действия',
    add: 'Добавить',
    main: 'Основные',
    sync: 'Синхронизировать',
    syncWith1C: 'Синхронизировать c 1C',
    idOneC: 'ID 1C',
    refId: 'ref_id',
    comment: 'Описание',
    countryLocation: 'Страна расположения',
    regionLocation: 'Регион расположения',
    manager: 'Руководитель подразделения',
    relatedDeliveryServices: 'Связанные службы доставки',
    city: 'Населенный пункт',
    region: 'Регион',
    country: 'Страна',
    modalTitle: 'Внимание',
    modalContent: 'Указанный id не найден',
    area: 'Области',
    amount: 'Кол-во',
    percent: '%',
    resultCol: 'Итого',
  },
  orderFraud: {
    orderFraud: 'Статистика Некорректных',
    applicationSource: 'Источник заявки',
    totalApplications: 'Всего заявок',
    incorrect: 'Некорректных',
    percentIncorrect: '% некорректных',
  },
  CurrencyConversion: {
    title: 'Конвертация валют',
    course: 'Курс валют',
    updated: 'Обновленно',
    rub: 'RUB',
    percentIncorrect: '% некорректных',
    at: 'в',
    noCurrenciesData: 'Нет данных',
    converterLink: 'apilayer.com',
    loading: 'Загрузка...',
  },
  exportReports: {
    general: 'Общее',
    callCenter: 'Колцентр',
    logistics: 'Логистика',
    finance: 'Финансы',
    redemptionByDay: 'Выкуп по дням',
    reportOrdersCourierDelivery: 'Отчет по заказам с курьерской доставкой',
    hourlyOrdersToday: 'Заказы по часам за сегодня',
    ordersHourSevenDays: 'Заказы по часам за 7 дней',
    notificationReportInactive: 'Отчет по уведомлениям (неактивный)',
    reportRlabNotifications: 'Отчет по RLAB уведомлениям',
    reportCanceledConfirmed: 'Отчет по отмененным подтвержденным',
    repeatCampaignReport: 'Отчет по кампаниям повторных продаж',
    reportSettings: 'Настройки отчета',
    download: 'Скачать',
    selectReport: 'Для настройки выберите отчет',
    applicationsSpecifiedPeriod: 'Заявки за указанный период',
    period: 'Период',
    selectFields: 'Выбор необходимых полей',
    fields: {
      offer: 'Оффер',
      salesModel: 'Модель продаж',
      niche: 'Ниша',
      dateCreate: 'Дата/время создания',
      dateApprove: 'Дата/время апрува',
      dateRansom: 'Дата/время выкупа',
      fio: 'ФИО клиента',
      gender: 'Пол клиента',
      age: 'Возраст клиента',
      birthDate: 'Дата рождения клиента',
      firstApproveDate: 'Дата/время первого апрува',
      comment: 'Комментарий',
      country: 'Страна',
      district: 'Область',
      city: 'Населенный пункт',
      address: 'Адрес',
      deliveryType: 'Тип доставки',
      deliveryService: 'Служба доставки',
      availableDeliveryServiceType: 'Доступные способы доставки для нас.пункта',
      deliveryPoint: 'Точка доставки',
      deliveryDate: 'Назначенная дата доставки',
      trackNumber: 'Трек номер',
      orderPrice: 'Cумма чека',
      web: 'Веб',
      landing: 'Лендинг',
    },
    statuses: {
      accepted: 'Принята',
      incorrect: 'Неправильный',
      processing: 'В обработке',
      approved: 'Апрув',
      canceled: 'Отменено',
      callback: 'Колбэк',
      trash: 'Треш',
      shipped: 'Отправленный',
      awaitingRansom: 'Ожидает выкупа',
      noRansom: 'Без выкупа',
      delivered: 'Доставлена',
      partiallyPaid: 'Частично оплачено',
      paid: 'Оплачено',
    },
    selectStatus: 'Статусы',
    salesModel: 'Модель продаж',
    deliveryService: 'Служба доставки',
    userGroups: 'Группы операторов',
    shippedMoreThan: 'Отправлены более 20 дней (1025_1)',
    shippedMoreThanNoBarcode: 'Отправлено более 15 дней без ШК (1025_2)',
    approvedMoreThan14DayCourier: 'Апрув более 14 дней для Курьер РФ (1025_3)',
    approvedMoreThan10Day: 'Апрув более 10 дней для РФ НЕ Курьер (1025_3.2)',
    awaitingRansomMoreThan30Day: 'Ожидает выкуп более 30 дней для Почта РФ (1025_4)',
    awaitingRansomMoreThan15Day: 'Ожидает выкуп более 15 дней кроме Почта РФ (1025_4.2)',
    ordersWithoutBarcode: 'Отчет по заказам без назначенного штрих-кода',
    deliveryIntervalsForDeliver: 'Интервалы доставки для Доставим',
    deliveryPointChanges: 'Изменения точек доставки',
    summaryPointReport: 'Общий балл КЛН за период',
  },
  smsTemplate: {
    title: 'СМС шаблоны',
    titleForm: 'Шаблон СМС',
    filter: {
      active: 'Активность',
    },
    table: {
      name: 'Название',
      createAt: 'Дата создания',
      author: 'Кто создал',
      actions: 'Действия',
    },
    form: {
      title: 'Настройка шаблона',
      name: 'Название',
      text: 'Текст СМС',
      prev: 'Предпросмотр',
      symbols: 'Символов:',
      sms: 'СМС:',
      info: 'Количество символов для подсчета и тарифицирования смс в шаблоне, является приблизительным и считается по <br> длине макроса со скобками. Точное количество тарифицируемых смс сообщает оператор связи',
    },
    macros: {
      fname: {
        label: 'Имя_клиента',
        text: 'Александр',
      },
      lname: {
        label: 'Фамилия_клиента',
        text: 'Александров',
      },
      patronymic: {
        label: 'Отчество_клиента',
        text: 'Александрович',
      },
      fullName: {
        label: 'ФИО_клиента',
        text: 'Александров Александр Александрович',
      },
      orderNumber: {
        label: 'Номер_заказа',
        text: '#1123',
      },
      phone: {
        label: 'Телефон',
        text: '+12345678900',
      },
      price: {
        label: 'Цена',
        text: '2500',
      },
      deliveryAddress: {
        label: 'Адрес_ПВЗ',
        text: 'г. Алматы, пр. Абылай Хана 3, Отделение №10',
      },
    },
  },
  workTimeLogType: {
    wait: 'Ожидание',
    notAvailable: 'Недоступен',
    manualCall: 'Звонки вручную',
    personalBreak: 'Личный перерыв',
    education: 'Обучение',
    technicalBreak: 'Технический перерыв',
    consultation: 'Консультация',
    kln: 'КЛН',
    done: 'Готов',
    pause: 'Не готов',
    modalInfo:
      'Количество операторов в статусе “Личный перерыв” достигло лимита. Пожалуйста, попробуйте позже.',
    infoErr: 'Лимит пользователей на перерыве превышен',
    technicalBreakShort: 'Тех. перерыв',
    personalBreakShort: 'Перерыв',
    orderProcessing: 'Обработка заявки',
  },
  workTime: {
    workTime: 'Учет времени операторов',
    operator: 'Оператор',
    timeLimit: 'Вы использовали свое личное время.',
    movedWorkTime: 'Вы перешли в статус “Личный перерыв”.',
    availableBreakTime: 'Доступное время для перерыва',
    ImHere: 'Я здесь',
    modalText: 'Вы бездействуете 3 мин. Система переведет ваш статус в “Недоступен” через 20 сек.',
    modalTitle: 'Внимание',
    filterTitle: 'Тип расчета времени',
    filterType: 'Тип расчета времени',
    reflectionSumNumber: 'Отражение суммы числа',
    reflectionAvg: 'Отражение среднего арифметического числа',
    workTimeTable: 'Рабочее время',
    autoCall: 'Автообзвон',
    done: 'Готово',
    applicationProcessing: 'Обработка заявки',
  },
  limitTime: {
    limitTimeTitle: 'Лимиты времени оператора',
    limitTime: 'Лимит времени',
    limitMinutes: 'Ограничения по минутам',
    limitTimePlaceholder: 'Ограничение времени оператора на личный перерыв на рабочий день, мин',
    limitTimeInaction: 'Время разрешенного бездействия оператора, мин',
  },
  district: {
    districts: 'Районы',
    district: 'Район',
    main: 'Основные',
    name: 'Название',
    region: 'Область',
    country: 'Страна',
    actions: 'Действия',
    addDistrict: 'Добавить',
    loadFromFile: 'Загрузить из файла',
  },
  orderTrash: {
    PotentialTrash: 'Потенциальный треш',
    date: 'Дата',
    lastCall: 'Последний набор',
    offers: 'Офферы',
    client: 'Клиент',
    countryLanguage: 'Страна/язык',
    showNumber: 'Показать номер',
    status: 'Статус',
    operator: 'Оператор',
    groups: 'Группы',
    comments: 'Комментарий',
    trash: 'Треш',
    dispute: 'Спорный треш',
    trashChecked: 'Обработанный треш',
    trashRaw: 'Необработанный треш',
  },
  ticketStatus: {
    new: 'Новый',
    in_progress: 'В работе',
    fixed: 'Закрыт',
    closed: 'Закрыт',
  },
  logAuth: {
    logAuth: 'Логи логинов/логаутов',
    nameOrEmail: 'ФИО',
    eventName: 'Название события',
    eventTime: 'Время события',
    IpAddress: 'IP адрес',
    region: 'Регион',
    browser: 'Браузер',
    login_success: 'Логин',
    login_fail: 'Неверный пароль',
    login_logout: 'Логаут',
    watch_number: 'Просмотр номера',
    open_order: 'Открытие заявки',
  },
  logOrder: {
    logOrder: 'Логи заявок',
    date: 'Дата',
    operator: 'Оператор',
    open: 'Открытие',
    close: 'Закрытие',
    duration: 'Длительность',
    offer: 'Оффер',
    phoneNumber: 'Номер телефона',
    status: 'Статус',
    reason: 'Причина',
    sum: 'Сумма',
    locality: 'Населенный пункт',
    deliveryType: 'Тип доставки',
    showNumber: 'Показать номер',
  },
  userPenalty: {
    usersPenalties: 'Штрафы операторов',
    userPenalty: 'Штраф',
    penaltyAmountRu: 'Сумма штрафа (руб)',
    penaltyAmountHrn: 'Сумма штрафа (грн)',
    penaltyAmountLe: 'Сумма штрафа (леи)',
    penaltyRu: 'Сумма (руб)',
    penaltyUa: 'Штраф(грн)',
    penaltyLe: 'Штраф(лей)',
    description: 'Описание',
    name: 'Название',
    main: 'Основные',
    actions: 'Действия',
  },
  region: {
    regions: 'Области',
    region: 'Область',
    main: 'Основные',
    country: 'Страна',
    timezone: 'Тайм-зона',
    name: 'Название',
    actions: 'Действия',
  },
  additionalOffers: {
    title: 'Связанные офферы',
    currentOffer: 'Текущий оффер',
    salesType: {
      repeated: 'Повторные продажи',
      cancelled: 'Отмены',
      no_ransom: 'Невыкупы',
      certificate: 'Сертификаты',
    },
  },
  questionnaires: {
    questionnaires: 'Анкеты',
    name: 'Название',
    actions: 'Действия',
    createQuestionnaires: 'Анкета',
    general: 'Общее',
    questions: 'Вопросы',
    main: 'Основные',
    country: 'Страна',
    salesType: 'Тип продаж',
    language: 'Язык',
    subtitle: 'Чтобы перейти на следующий таб, заполните форму и сохраните страницу.',
    dependent: 'Зависимый',
    addAnswer: 'Добавить ответ',
    deleteAnswer: 'Удалить вопрос',
    answer: 'Вопрос',
    answerName: 'Имя',
    add: 'Добавить',
    openAnswer: 'Открытый ответ',
    filterCountries: 'Cтраны',
    filterOffers: 'Офферы',
    filterGroupOffers: 'Группы офферов',
    filterTags: 'Теги',
    filterOperators: 'Операторы',
    filterOperatorsGroup: 'Группы операторов',
    filterSalesType: 'Тип продаж',
    hotOperator: 'Оператор горячей',
    questionnaireOperator: 'Оператор анкетирования',
    offer: 'Оффер',
    result: 'Результат',
    operator: 'Оператор анкетированя',
    group: 'Группы',
    questionnaire: 'Анкетирование',
    numberOfRecords: 'Количество записей',
    dependOnAnswer: 'от ответа',
    dependOnAnswerQuestion: 'на вопрос',
    applicationForm: 'Заявка анкеты',
    dateHot: 'Дата горячей',
    hotGroupOperator: 'Группа оператора горячей',
    date: 'Дата анкетирования',
    inputQuestion: 'Введите вопрос',
    errorMessage:
      'Вопрос должен иметь как минимум один вариант ответа. Добавьте ответ либо удалите этот вопрос',
  },
  organization: {
    name: 'Название',
    activity: 'Активность',
    syncWith1C: 'Синхронизировать с 1С',
  },
  operatorGrade: {
    title: 'Грейды операторов',
    saleType: {
      hot: 'Горячие',
      repeated: 'Повторные',
    },
    operator: 'Оператор',
    saleTypeTitle: 'Направление продаж',
    workStartDate: 'Трудоустроен (мес)',
    groups: 'Группа',
    approvePercentOvp: '% aпрува',
    ransomPercentOvp: '% выкупа',
    approveCheckOvp: 'Средний чек апрува',
    ransomCheckOvp: 'Средний чек выкупа',
    ransomCheckOvpAndRansomPercentOvp: 'Средний чек и % выкупа',
    workTime: 'Время онлайн',
    efficiency: 'Эффективность',
    kln: 'КЛН',
    recommendedGrade: 'Соответствие грейду',
    grade: 'Грейд',
    KpiNorms: 'Нормы KPI',
    normsKpiForGrade: 'Нормы KPI для повышения грейда',
    hot: 'Горячие продажи',
    resales: 'Повторные продажи',
    condition: 'Условия',
    intern: 'Стажер',
    manager: 'Менеджер',
    specialist: 'Специалист',
    professional: 'Профиссионал',
    master: 'Мастер',
    workTimeTable: 'Рабочее время',
    effectiveness: 'Эффективность',
    checkAndRansomPercent: 'ОВП по чеку и % выкупа',
    checkAndApprove: 'ОВП по чеку аппрува',
    checkAndRansom: 'ОВП по чеку выкупа',
    checkAndApprovePercent: 'ОВП по % аппрува',
    ransomCheck: 'ОВП по % выкупа',
    knlScores: 'Баллы КЛН',
    error: 'Ошибка вычисления',
  },
  cityImport: {
    addFile: 'Добавить файл',
    loadFromFile: 'Загрузить из файла',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    preview: 'Предпросмотр',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    country: 'Страна',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    importCity: 'Импорт населенных пунктов',
    field: {
      region: 'Область',
      district: 'Район',
      city: 'Населенный пункт',
      deliveryType: 'Тип доставки',
      deliveryService: 'Служба доставки (тип)',
      warehouse: 'Склад',
      errors: 'Ошибки',
      name: 'Город',
    },
  },
  delivery: {
    deliveryCourier: 'Курьерская доставка',
    deliveryPoints: 'Пункт выдачи',
    deliveryPost: 'Почта',
    list: {
      bareCode: 'Штрихкод',
      orderList: 'Состав заказа',
      productsAmount: 'Кол-во продуктов',
      dateApprove: 'Дата апрува',
      deliveryWaiting: 'Ожидает отправки',
      deliveryDate: 'Дата доставки',
      city: 'Нас.пункт',
      district: 'Район',
      region: 'Область',
      country: 'Страна',
      catalog: 'Каталог',
      terminalPayment: 'Терминал',
      dateToDeliveryService: 'Дата отправки в сервис',
      comments: 'Комментарий',
      deliveryServiceAnswer: 'Ответ СД',
      offers: 'Оффер',
      postAddress: 'Адресс',
      operator: 'Оператор',
    },
    filter: {
      countries: 'Страны',
      regions: 'Области',
      districts: 'Районы',
      cities: 'Нас.пункты',
      offer: 'Офферы',
      groupOffers: 'Группы офферов',
      operator: 'Операторы',
      terminalPayment: 'Наличие терминала',
      tags: 'Теги',
    },
  },
  smsCampaign: {
    title: 'СМС кампании',
    table: {
      id: 'ID',
      name: 'Название',
      template: 'Шаблон',
      sender: 'Имя отправителя',
      orderStatus: 'Статус заявки',
      dayInStatus: 'Дней в статусе',
      active: 'Статус кампании',
    },
    filter: {
      date: 'Дата',
      active: 'Активность',
      deliveryServices: 'Cлужба доставки',
      offers: 'Офферы',
      country: 'Cтраны',
      templates: 'Шаблоны',
      nameSender: 'Имя отправителя',
    },
    form: {
      title: 'СМС кампания автоматическая',
      titleManual: 'СМС кампания ручная',
      titleItem: 'Настройки кампании',
      titleItemManual: 'Основные',
      titleMain: 'Основное',
      name: 'Название',
      template: 'Шаблон',
      sender: 'Имя отправителя',
      country: 'Страна',
      delivery: 'Служба доставки',
      active: 'Статус кампании',
      offers: 'Офферы',
      orderStatus: 'Статус заявки',
      startDate: 'Дата присвоения статуса с',
      startDateManual: 'Дата старта кампании',
      endDate: 'Дата присвоения статуса по',
      dayInStatus: 'Дней в статусе',
      service: 'Тип доставки',
    },
  },
  smsList: {
    title: 'Отчет СМС кампаний',
    id: 'ID Заявки',
    status: 'СМС',
    smsCount: 'Кол-во СМС',
    sendDate: 'Дата/время',
    smsStatus: 'Статусы СМС',
    getStatus: 'Запросить статус',
    sendMessage: 'Сообщение доставлено',
  },
  smsStatuses: {
    sent: 'Отправлено',
    pending: 'Ожидается',
    error: 'Ошибка',
  },
  orderStatuses: {
    accepted: 'Принят',
    processing: 'Обработка',
    incorrect: 'Некорректный',
    approved: 'Апрув',
    shipped: 'Отправлен',
    canceled: 'Отмена',
    delivered: 'Выкуп',
    no_ransom: 'Невыкуп',
    awaiting_ransom: 'Ожидает выкуп',
    callback: 'Колбэк',
    trash: 'Треш',
    paid: 'Оплачено',
    partially_paid: 'Частично оплачено',
    chooseCols: 'Выбрать колонки',
  },
  evaluationOptions: {
    yes: 'Дa',
    no: 'Нет',
    partial: 'Частично',
    dont_consider: 'Не учитывать',
  },
  offerNorms: {
    offer: 'Оффер',
    averageCheck: 'Средний чек апрува',
    approve: 'Апрув',
    ransomCheck: 'Чек выкупа',
    ransom: 'Выкуп',
    top: 'Топ',
    avg: 'Среднее',
    mediana: 'Медиана',
    norma: 'Норма',
  },
  salesScriptCopy: {
    title: 'Введите название',
    copy: 'Копия - ',
  },
  salesModel: {
    salesModel: 'Модель продаж',
    newSalesModel: 'Добавить',
    salesModelAdd: 'Добавить модель продаж',
    salesModelEdit: 'Редактировать модель продаж',
    name: 'Название',
    slug: 'Название латиницей',
  },
  managerDashboard: {
    title: 'Дашборд',
    ordersChartTitle: 'Заказы',
    hangingRansomChartTitle: 'Зависший выкуп (по отправке)',
    deliveryServiceOrderChartTitle: 'Отправки, выкупы и оплаты по СД',
    approvesPensionerChartTitle: 'Процент апрувов 55+',
    deliveryServiceDebtChartTitle: 'Долг по оплатам СД',
    approvesByTimeChartTitle: 'Апрувы по времени суток',
    amountAndAverageChartTitle: 'Количество и среднее время заявок в статусе',
    allApprovePerDay: 'Всего апрувов за день',
    approvePerDay: 'Апрувы 55+ за день',
    orderPerDay: 'Всего поступило заказов за день',
    approvesPerDay: 'Всего апрувов за день',
    approves: 'Апрувы',
    avgTimeToApprove: 'Среднее время от захода до апрува',
    send: 'Отправлено',
    redeemed: 'Выкуплено',
    paid: 'Оплачено',
    widgets: 'Виджеты',
    overThePast: 'последние 30 дней',
    status: 'Статус',
    count: 'Кол-во',
    time: 'Время',
    sum: 'Сумма',
    groupOperators: 'Группа операторов',
    salesType: 'Тип продаж',
    deliveryType: 'Способ доставки',
    deliveryService: 'Служба доставки',
    statusHangingRansom: {
      shipped: 'Отправлено',
      processing: 'Обработка',
      approved: 'Апрув',
      canceled: 'Отмена',
      accepted: 'Принят',
      callback: 'Колбек',
      awaiting_ransom: 'Ожидает выдачи',
      ransom: 'Выкуп',
      no_ransom: 'Невыкуп',
      debt: 'Долг СД',
      delivered: 'Выкуп',
      partially_paid: 'Част. оплачено',
      paid: 'Оплачено',
      incorrect: 'Некорректный',
      trash: 'Треш',
      total: 'Всего',
    },
  },
  salaryBonus: {
    salaryBonus: 'Матрица для осн. ЗП КЦ',
    country: 'Страна',
    operatorGrade: 'Грейд оператора',
    salesModel: 'Модель продаж',
    checkCurrency: 'Валюта чека',
    checkFrom: 'Чек от',
    checkUp: 'Чек до',
    basePayOperator: 'Базовая оплата оператору',
    additionalPaymentForGradeOperator: 'Доплата за грейд оператору',
    additionalPaymentForCheckOperator: 'Доплата за чек оператору',
    basicPaymentForTeamLeadTeamLead: 'Базовая оплата тимлиду',
    additionalPaymentForGradeTeamLead: 'Доплата за грейд тимлиду',
    additionalPaymentForCheckTeamLead: 'Доплата тимлиду за чек',
    totalPaymentOperator: 'Итого оплата оператору',
    totalPaymentTeamLead: 'Итого оплата тимлиду',
    filterCountry: 'Страны',
    filterSalesModel: 'Модели продаж',
    filterOperatorGrade: 'Грейды оператора',
    import: 'Импорт данных для ЗП КЦ',
    infoImport:
      'Скачайте шаблон для заполнения данных, заполните и загрузите.<br/>Загружаемый файл полностью перезапишет данные в системе.',
    importData: 'Импортировать ',
    exportTable: 'Скачать таблицу для редактирования можно здесь',
    modalImport:
      'Будут импортированы только те строки, которые не содержат<br/> ошибок. Данные будут применены при ближайшем расчете ЗП.',
    warningText: 'Загружаемый файл полностью перезапишет<br/>данные в системе.',
    field: {
      amountFrom: 'Чек от',
      amountTo: 'Чек до',
      baseOperatorAmount: 'Базовая оплата оператору',
      baseTeamLeaderAmount: 'Базовая оплата тимлиду',
      country: 'Страна',
      grade: 'Грейд оператора',
      operatorCheckSurcharge: 'Доплата за чек оператору',
      operatorGradeSurcharge: 'Доплата за грейд оператору',
      salesModel: 'Модель продаж',
      teamLeaderCheckSurcharge: 'Доплата тимлиду за чек',
      teamLeaderGradeSurcharge: 'Доплата за грейд тимлиду',
      errors: 'Ошибки',
    },
  },
  dashboardNotifications: {
    notifications: 'Уведомления',
    clear: 'Очистить',
    unread: 'Непрочитанные',
    read: 'Прочитанные',
    listIsEmpty: 'Список уведомлений пуст',
    loading: 'Загрузка уведомлений',
  },
  notifications: {
    operator_not_ready_for_callback: 'Неготовность оператора',
    duty_manager_absent: 'Дежурный менеджер',
    personal_break_time_limit: 'Лимит личного перерыва',
    personal_break_time_left: 'Осталось времени перерыва',
    order_manager_help: 'Помощь менеджера по заказам',
    product_deactivated: 'Деактивация продукта',
    mng_handing_ransom: 'Задержка выплат от СД',
  },
  prepayment: {
    title: 'Аванс 1',
    cardNumber: 'Номер карты',
    fullName: 'Фамилия, имя, отчество',
    amount: 'Сумма (руб)',
    comment: 'Комментарий',
    total: 'Итого',
    supervisor: 'Руководитель',
    userGroups: 'Группа пользователей',
    titleTwo: 'Аванс 2',
  },
  additionalProjects: {
    title: 'Эффективность доп проектов',
    hot: 'Горячие',
    correct: 'Корректных',
    approve: 'Аппрув',
    ransom: 'Выкуп',
    efficiencyAdditionalProjects: 'Эф.доп проектов',
    catalog: 'Каталог',
    rub: '(руб)',
    catalogs: 'Каталоги',
    repeated: 'Повторные',
    cancellation: 'Отмены',
    nonRedemption: 'Невыкуп',
    income: 'Доход',

    filterOffers: 'Офферы',
    filterOfferGroup: 'Группы офферов',
    filterWeb: 'Вебы',

    offer: 'Оффер',
    web: 'Веб',
  },
  operatorDashboard: {
    applications: 'Заявки',
    topOperatorsTooltip: 'Топ операторов по подтвержденным заказам',
    currentGrade: 'Текущий грейд',
    statusHangingRansom: {
      approved: 'Апрув',
      canceled: 'Отмена',
      delivered: 'Выкуп',
      paid: 'Оплачено',
      partially_paid: 'Част. оплачено',
      awaiting_ransom: 'Ожидает выкуп',
      ransom: 'Выкуп',
      no_ransom: 'Невыкуп',
      shipped: 'Отправлено',
      callback: 'Колбек',
      debt: 'Долг СД',
      accepted: 'Принят',
      processing: 'Обработка',
      incorrect: 'Некорректный',
      trash: 'Треш',
      total: 'Всего',
    },
    noAvailableData: 'Нет данных',
    hotTop: 'Топ горячие',
    top: 'Топ операторов',
    ratingError: 'Ошибка расчета рейтинга',
    repeatedTop: 'Топ повторные',
    yourRating: 'Ваш рейтинг',
  },
  calendar: {
    months: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
    },
  },
  klnStatistic: {
    evaluationPoints: 'Пункты оценки',
    noDataMessage: 'Недостаточно данных для отображения',
    groupsInformation: 'Информация по группам',
    moreInfo: 'Подробнее',
    filter: {
      groupOperators: 'Группы операторов',
      operators: 'Операторы',
      offerGroups: 'Группы офферов',
      offers: 'Офферы',
      niches: 'Ниши',
      salesTypes: 'Типы продаж',
      salesModels: 'Модели продаж',
      country: 'Страна',
      regions: 'Области',
      deliveryTypes: 'Типы доставки',
      deliveryServices: 'Cлужбы доставки',
    },
  },
  klnAssessment: {
    title: 'Оценки КНЛ',
    export: 'Экспорт',
    orderNumber: 'Заказ №',
    status: 'Статус',
    niches: 'Ниша',
    offer: 'Оффер',
    operator: 'Оператор',
    dateValue: 'Дата оценивания',
    operatorsGroup: 'Группы опереторов',
    total: 'Всего',
    workStatus: {
      created: 'Не обработано',
      pending: 'В работе',
      finished: 'Обработано',
    },
    addAudio: 'Добавить запись',
    listAudio: 'Список аудио',
    descInfoModal:
      'Заявка в статусе “В работе”. Возможно, ее редактирует другой сотрудник. При нажатии на “Сохранить” данные будут перезаписаны.',
  },
  webAnalytica: {
    title: 'Выплаты вебам',
    date: 'Дата',
    web: 'Веб',
    source: 'Источник',
    order: 'Заказ',
    offer: 'Оффер',
    typeProduct: 'Тип продукта',
    bid: 'Ставка (руб)',
    offerNiche: 'Ниша оффера',
  },
  questionnairesStatistics: {
    statistics: 'Статистика',
    form: 'Анкета',
    period: 'Период',
    btnConfirm: 'К заказу',
    title: 'Анкета от',
    titleTwo: 'по заявке',
    questionAnswer: 'Вопрос/Ответ',
    hotOperator: 'Оператор горячей',
    operator: 'Оператор анкеты',
    export: 'Выгрузить XLS',
    back: 'К списку',
    total: 'Всего',
  },
  paymentHold: {
    paymentHold: 'Удержание',
    paymentHolds: 'Удержания',
    main: 'Основные',
    name: 'Название',
    amount: 'Сумма (руб)',
    description: 'Описание',
    penaltyRu: 'Сумма (руб)',
    comment: 'Описание',
    actions: 'Действия',
  },
  paymentProfit: {
    paymentProfit: 'Начисление',
    paymentProfits: 'Начисления',
    main: 'Основные',
    name: 'Название',
    amount: 'Сумма (руб)',
    description: 'Описание',
    comment: 'Описание',
    actions: 'Действия',
  },
  modalSuccessError: {
    close: 'Закрыть',
    success: 'Успех',
    error: 'Ошибка',
    successSync: 'Синхронизация успешно завершена',
    errorSync: 'Во время операции произошла ошибка синхронизации',
  },
  notFound: {
    title: 'Ой, произошла ошибка...',
    text: 'Страница не существует или у вас нет прав доступа к ней.',
    btnText: 'На главную',
  },
  regionImport: {
    regionImport: 'Импорт областей',
    country: 'Страна',
    preview: 'Предпросмотр',
    importData: ' Импортировать данные',
    addFile: 'Добавить файл',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    field: {
      name: 'Имя',
      timezone: 'Таймзона',
      errors: 'Ошибки',
    },
  },
  districtImport: {
    districtImport: 'Импорт районов',
    country: 'Страна',
    preview: 'Предпросмотр',
    importData: ' Импортировать данные',
    addFile: 'Добавить файл',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    field: {
      region: 'Область',
      name: 'Район',
      errors: 'Ошибки',
    },
  },
  hangingRansom: {
    title: 'Зависший выкуп',
    day: 'День',
    totalRansom: 'Всего выкуплено',
    sum: 'Сумма',
    paid: 'Оплачено',
    percent: '%',
    partPaid: 'Частично оплачено',
    sumCheck: 'Сумма к оплате',
    sumPaid: 'Сумма оплачено',
    waitPaid: 'Ожидает оплаты',
    countOrderCredit: 'Кол-во заказов с долгом',
    creditCd: 'Долг СД',
    total: 'Итого',
    offer: 'Оффер',
    statusChart: {
      paid: 'Оплачено:',
      waitPaid: 'Ожидает опл:',
      partPaid: 'Частично опл:',
      deptCd: 'Долг СД:',
      total: 'Всего выкуп:',
    },
    filters: {
      offer: 'Оффер',
      groupOffer: 'Группа офферов',
      tags: ' Теги',
      offerNiche: 'Ниша офферов',
      salesModel: 'Модель продаж',
      deliveryService: 'Служба доставки',
      deliveryType: 'Способ доставки',
      countries: 'Страна',
      regions: 'Область',
      districts: 'Район',
      cities: 'Населенный пункт',
      userGroups: 'Группа пользователей',
      web: 'Веб',
      questionnaires: 'Анкета повторных',
    },
    filtersSelect: {
      month: 'Месяц',
      country: 'Cтрана',
      district: 'Область',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      delivery_service: 'Cлужба доставки',
      sales_model: 'Модель продаж',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      approve: 'По апруву',
      shipped: 'По отправке ',
      delivered: 'По выкупу',
    },
  },
  averageCheck: {
    title: 'Средние чеки',
    day: 'День',
    orders: 'Заказов',
    total: 'Всего',
    correct: 'Корректных',
    wasCall: 'Был звонок',
    confirmed: 'Подтверждено',
    counts: 'Кол-во',
    percentCorrect: '% от Корректных',
    percentTotal: '% от Всего',
    totalMinutes: 'Время (м.)',
    check: 'Чек',
    sent: 'Отправлено',
    percentConfirmed: '% от Подтвер.',
    redeemedAverageCheck: 'Выкуплено',
    percentSent: '% от Отправл.',
    male: 'Мужской',
    female: 'Женский',
    filters: {
      countries: 'Страны',
      regions: 'Области',
      city: 'Населенные пункты',
      deliveryService: 'Службы доставки',
      offers: 'Офферы',
      offersGroup: 'Группы офферов',
      offersNiche: 'Ниши офферов',
      operators: 'Операторы',
      landings: 'Лендинги',
      webs: 'Вебы',
      salesModel: 'Модели продаж',
      productType: 'Типы продуктов',
      operatorGroups: 'Группы пользователей',
      age: 'Возраст',
      gender: 'Пол',
    },
    week: 'Неделя',
    month: 'Месяц',
    processingDate: 'Дата обработки',
    offer: 'Оффер',
    groupOffer: 'Группа офферов',
    offerNiches: 'Ниша офферов',
    countries: 'Страна',
    district: 'Область',
    city: 'Населенный пункт',
    operatorGroup: 'Группа операторов',
    operator: 'Оператор',
    landing: 'Лендинг',
    web: 'Веб',
    rejectReason: 'Причина отмены',
    deliveryService: 'Служба доставки',
    salesModel: 'Модель продаж',
    productType: 'Тип продукта',
    age: 'Возраст',
    gender: 'Пол',
    warehouse: 'Склад отправки',
    filterDateBy: {
      createdAt: 'По заходу',
      processingDate: 'По обработке',
      approveDate: 'По апруву',
      shippedDate: 'По отправке',
      ransomDate: 'По выкупу',
    },
  },
  deliveryExcludedRules: {
    main: 'Основные',
    name: 'Название',
    country: 'Страна',
    countries: 'Страны',
    offers: 'Офферы',
    regions: 'Области, для которых исключаются СД',
    deliveryServices: 'Исключенные службы доставки',
    list: {
      deliveryServices: 'Исключенные СД',
      regions: 'Области',
    },
  },
  legalPersonsStats: {
    title: 'Статистика по юр. лицам',
    countries: 'Страны',
    regions: 'Области',
    deliveryTypes: 'Способы доставки',
    levelTypes: {
      warehouse: 'Склад отправки',
      organization: 'Юр. лицо',
      deliveryService: 'Служба доставки',
      deliveryType: 'Способ доставки',
      offer: 'Оффер',
      product: 'Продукт',
      country: 'Страна',
      region: 'Область',
    },
    sentVolume: 'Отправлено (объем)',
    volumePercent: '% объема',
    volumePercentTotal: '% объема общий',
    dateOfFirstShipment: 'Дата первой отправки',
    dateOfLastShipment: 'Дата последней отправки',
    averageCheck: 'Ср. чек',
    total: 'Всего отправлено заказов',
  },
  deliveryServiceAnalytic: {
    title: 'Аналитика служб доставки',
    regions: 'Области',
    redeemed: 'Выкуплено',
    sent: 'Отправлено',
    percent: '%',
    select: {
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      country: 'Страна',
      district: 'Район',
      city: 'Населенный пункт',
      user_group: 'Группа операторов',
      operator: 'Оператор',
      landing: 'Лендинг',
      web: 'Веб',
      delivery_service: 'Служба доставки',
      sales_model: 'Модель продаж',
      product_type: 'Тип продукта',
      warehouse: 'Склад отправки',
    },
    isActiveCity: 'Статусы нас-ых пунктов',
    groupDateBy: {
      year: 'Год',
      quarter: 'Квартал',
      month: 'Месяц',
      week: 'Неделя',
      day: 'День',
    },
    totalType: {
      totalAll: 'За весь возможный период',
      totalCurrent: 'За выбранный период в фильтрах',
    },
    filters: {
      deliveryServices: 'Службы доставки',
      countries: 'Страны',
      regions: 'Области',
      districts: 'Районы',
      cities: 'Населенные пункты',
      deliveryTypes: 'Способы доставки',
      warehouses: 'Склады',
      checkFrom: 'Чек заказа от',
      checkTo: 'Чек заказа до',
      offers: 'Офферы',
      products: 'Продукты',
      groupDate: 'Группировка по периоду',
      totalType: 'Расчет итога',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      approveDate: 'По апруву',
      firstApproveDate: 'По дате первого апрува',
      shippedDate: 'По отправке',
      deliveryDate: 'По доставке',
      ransomDate: 'По выкупу',
      lastStatus: 'По дате конечного статуса',
    },
    totalAll: 'Итог за весь возможный период',
    shippedOrders: 'Отгружено заказов',
    ransom: 'Выкуп',
  },
  deliveryPointsImport: {
    title: 'Импорт точек доставки',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    preview: 'Предпросмотр',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    addFile: 'Добавить файл',
    labels: {
      country: 'Страна',
      status: 'Статус',
    },
    field: {
      name: 'Название',
      city: 'Населенный пункт',
      region: 'Область',
      deliveryService: 'Служба доставки',
      address: 'Адрес',
      phone: 'Телефон',
      integrationKey: 'Ключ интеграции',
      minDays: 'Мин. дней',
      maxDays: 'Макс. дней',
      comment: 'Комментарий',
      workTime: 'Время работы',
      terminal: 'Терминал',
      errors: 'Ошибки',
    },
  },
  welcomePage: {
    singleSolution: 'Единое решение для:',
    callCentre: 'Колл-центра',
    logistics: 'Логистики',
    management: 'Менеджмента',
  },
  distributionCheck: {
    title: 'Распределение чеков',
    totalApprove: 'Всего апрувов',
    filters: {
      offer: 'Оффер',
      offerGroups: 'Группа офферов',
      niche: 'Ниша',
      web: 'Веб',
      landings: 'Лендинг',
      salesModel: 'Модель продаж',
      salesType: 'Тип продаж',
      operators: 'Оператор',
      userGroups: 'Группа пользователей',
      country: 'Страна',
      region: 'Область',
    },
  },
  userSalary: {
    title: 'ЗП операторов',
    total: 'Итого',
    cardNumber: 'Номер карты',
    fullName: 'Фамилия, имя, отчество',
    amount: 'Сумма (руб)',
    comment: 'Комментарий',
    supervisor: 'Руководитель',
    userGroups: 'Группа пользователей',
  },
  buybackDynamics: {
    title: 'Динамика выкупа по дням',
    total: 'Итого',
    table: {
      dayDifference: 'Разница дней',
      redeemed: 'Выкуплено',
      redeemedPercent: '% выкуплено',
      sent: 'Отправлено',
      notRedeemed: 'Не выкуплено',
      redemptionVolume: 'Объем выкупаемости',
    },
    filters: {
      deliveryServices: 'Службы доставки',
      countries: 'Страны',
      regions: 'Области',
      deliveryTypes: 'Способы доставки',
      warehouses: 'Склады',
      checkFrom: 'Чек заказа от',
      checkTo: 'Чек заказа до',
      offers: 'Офферы',
      products: 'Продукты',
      tags: 'Теги',
    },
  },
  reportWithoutBarcode: {
    city: 'Населенный пункт',
    downloadFileName: 'Выгрузка без ШК',
  },
  callController: {
    title: 'Звонки',
    id: 'ID',
    operator: 'Оператор',
    orderId: 'ID заказа',
    status: 'Статус',
    showNumber: 'Показать номер',
    startOfCall: 'Начало звонка',
    duration: 'Продолжительность',
    callRecord: 'Запись звонка',
  },
  rejectionStatistics: {
    title: 'Статистика по отклоненным заявкам',
    table: {
      day: 'День',
      total: 'Всего',
      rejected: 'Отклоненные',
    },
    total: 'Итого',
    filtersGroup: {
      status: 'Статус',
      status_reason: 'Причина статуса',
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      user_group: 'Группа операторов',
      country: 'Страна',
      web: 'Веб',
      operator: 'Оператор',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
    },
    filterDateBy: {
      createdAt: 'по заходу',
      approve: 'по апруву',
      processing: 'по обработке',
    },
    filters: {
      groupOperators: 'Группы операторов',
      operators: 'Операторы',
      groupOffers: 'Группы офферов',
      offers: 'Офферы',
      niches: 'Ниши',
      salesTypes: 'Типы продаж',
      salesModel: 'Модели продаж',
      countries: 'Страны',
      regions: 'Области',
      deliveryTypes: 'Способы доставки',
      deliveryServices: 'Службы доставки',
    },
    statuses: {
      canceled: 'Отмена',
      callback: 'Колбек',
      trash: 'Треш',
    },
  },
}
export default locale
