export default [
  {
    path: '/partners',
    name: 'pagePartnersList',
    meta: { title: 'partners.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_view'] },
    component: () => import('@/pages/partners'),
  },
  {
    path: '/partners/create',
    name: 'pagePartnersCreate',
    meta: { title: 'partners.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_view'] },
    component: () => import('@/pages/partners/create'),
  },
  {
    path: '/partners/:id/edit',
    name: 'pagePartnersEdit',
    meta: { title: 'partners.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_view'] },
    component: () => import('@/pages/partners/id/edit'),
  },
  {
    path: '/partners/:id/settings',
    name: 'pagePartnerSettings',
    meta: { title: 'partners.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_view'] },
    component: () => import('@/pages/partners/id/settings'),
  },
]
