import SuperRepository from '../superRepository'

export default new (class DashboardRepository extends SuperRepository {
  baseUri() {
    return 'dashboard'
  }
  efficiencyChart = async params =>
    this.httpClient().get(`${this.baseUri()}/efficiency_chart`, params)
  topPartners = async params =>
    this.httpClient().get(`${this.baseUri()}/top_partners_chart`, params)
  topCountries = async params =>
    this.httpClient().get(`${this.baseUri()}/top_country_chart`, params)
})()
