<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="innerDate"
    transition="scale-transition"
    offset-y
    z-index="500"
    max-width="300"
    content-class="ui-date-picker-menu"
  >
    <template v-slot:activator="props">
      <slot
        name="activator"
        v-bind="{
          ...props,
          inputValue: formattedValue,
        }"
      />
    </template>

    <v-date-picker v-model="innerDate" type="date" locale="en-EN" v-bind="datePickerSettings">
      <v-container fluid class="py-3 px-1">
        <ui-time-picker v-model="innerTime" v-if="showTime">
          <template #activator="props">
            <ui-text-field :value="props.inputValue" dense readonly v-on="props.on" class="mb-4">
              <template #prepend-inner>
                <v-icon color="black lighten-2" size="large" style="margin-top: 2px"
                  >mdi-clock-time-five-outline</v-icon
                >
              </template>
            </ui-text-field>
          </template>
        </ui-time-picker>

        <v-row>
          <v-col cols="6" class="pr-1">
            <ui-button theme="outline" block class="w-100" size="small" @click="menu = false">
              {{ $t('base.cancel') }}
            </ui-button>
          </v-col>

          <v-col cols="6" class="pl-1">
            <ui-button block class="w-100" size="small" @click="onApply">
              {{ $t('base.apply') }}
            </ui-button>
          </v-col>
        </v-row>
      </v-container>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {
  DATE_TIME_FORMAT,
  TIME_SHORT_FORMAT,
  DATE_FORM_FORMAT,
  DATE_DAY_MONTH_FORMAT,
} from '@/constants/date'
import { isFunction } from 'lodash'
import UiTimePicker from '@/components/ui/UIDatePickerField/UiTimePicker.vue'

export default {
  name: 'ui-date-single-picker',
  components: { UiTimePicker },
  props: {
    value: {
      type: String,
      default: '',
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    displayDateFormatter: {
      type: Function,
      default: null,
    },
    returnValueFormat: {
      type: String,
      default: DATE_TIME_FORMAT,
    },
  },
  data() {
    return {
      menu: false,
      innerDate: '',
      innerTime: '00:00',
    }
  },
  computed: {
    formattedValue() {
      if (!this.innerDate) {
        return ''
      }

      if (isFunction(this.displayDateFormatter)) {
        return this.displayDateFormatter(this.innerDate)
      }

      return `${this.$moment(this.innerDate).format(DATE_DAY_MONTH_FORMAT)}`
    },
    headerText() {
      if (!this.innerDate) {
        return ''
      }

      return `${this.$moment(this.innerDate).format('DD MMM')}`
    },
    datePickerSettings() {
      const defaultSettings = {
        showCurrent: true,
        flat: true,
        color: 'primary base',
        scrollable: true,
        width: '300',
        firstDayOfWeek: 1,
        locale: this.$i18n.locale,
        selectedItemsText: this.headerText,
      }

      return {
        ...defaultSettings,
        ...this.settings,
        range: false,
      }
    },
  },
  watch: {
    value: {
      handler(date) {
        if (date) {
          this.innerDate = this.$moment(date).format(DATE_FORM_FORMAT)
          this.innerTime = this.$moment(date).format(TIME_SHORT_FORMAT)
        }
      },
      immediate: true,
    },
  },
  methods: {
    onApply() {
      this.$refs.menu.save(this.innerDate)
      if (!this.innerDate) {
        this.$emit('input', '')
        return
      }

      const date = this.$moment(this.innerDate)

      const [hours, minutes] = this.innerTime.split(':')
      date.add(hours, 'hours')
      date.add(minutes, 'minutes')

      this.$emit('input', date.format(this.returnValueFormat))
    },
  },
}
</script>

<style lang="scss">
.ui-date-picker-menu {
  border-radius: 16px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.08);
}
.ui-text-field-disabled {
  opacity: 0.6;
}
</style>
