export default [
    {
        path: '/players-reports',
        name: 'pagePlayersReportsList',
        meta: { title: 'playersReports.listTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/players-reports'),
    },
    {
        path: '/players-reports/create',
        name: 'pagePlayersReportsCreate',
        meta: { title: 'playersReports.createFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/players-reports/create'),
    },
    {
        path: '/players-reports/:id/edit',
        name: 'pagePlayersReportsEdit',
        meta: { title: 'playersReports.editFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/players-reports/id/edit'),
    }
]
