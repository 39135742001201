import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import DashboardRepository from "@/repository/generatedRepository/dashboardRepository";
import {defaultFilters} from "@/models/Dashboard/helpers";

const state = {
  filters: {
    date: StorageService.get(storageKeys.DASHBOARD_FILTERS) || { ...defaultFilters }.filter,
  },
  group: StorageService.get(storageKeys.DASHBOARD_EFFICIENCY_CHART) || 'day',
  efficiencyChartData: {},
  topPartners: [],
  topCountries: [],
}

const getters = {
  filters: state => state.filters,
  efficiencyChartData: state => state.efficiencyChartData,
  date: state => state.filters.date,
  group: state => state.group,
  topPartners: state => state.topPartners,
  topCountries: state => state.topCountries,
}
const actions = {
  async loadEfficiencyChart(context, payload) {
    const { isUpdate, group, ...date } = payload
    StorageService.set(storageKeys.DASHBOARD_EFFICIENCY_CHART, group)
    context.commit('setChartGroup', group)
    const {data} = StorageService.get(storageKeys.DASHBOARD_CHART_DATA) && isUpdate ?
      {data: StorageService.get(storageKeys.DASHBOARD_CHART_DATA)} :
      await DashboardRepository.efficiencyChart({...date, group})
    if(Object.keys(data).length < 5000) StorageService.set(storageKeys.DASHBOARD_CHART_DATA, data)
    context.commit('setEfficiencyChart', data)
    return data
  },
  async loadTopPartners(context, payload) {
    const { isUpdate, ...date } = payload
    const {data} = StorageService.get(storageKeys.DASHBOARD_TOP_PARTNERS_DATA) && isUpdate ?
      {data: StorageService.get(storageKeys.DASHBOARD_TOP_PARTNERS_DATA)} :
      await DashboardRepository.topPartners(date)
    if(data.length < 5000) StorageService.set(storageKeys.DASHBOARD_TOP_PARTNERS_DATA, data)
    context.commit('setTopPartners', data)
    return data
  },
  async loadTopCountries(context, payload) {
    const { isUpdate, ...date } = payload
    const {data} = StorageService.get(storageKeys.DASHBOARD_TOP_COUNTRIES_DATA) && isUpdate ?
      {data: StorageService.get(storageKeys.DASHBOARD_TOP_COUNTRIES_DATA)} :
      await DashboardRepository.topCountries(date)
    if(data.length < 5000) StorageService.set(storageKeys.DASHBOARD_TOP_COUNTRIES_DATA, data)
    context.commit('setTopCountries', data)
    return data
  },
  updateDateFilter(context, date){
    StorageService.set(storageKeys.DASHBOARD_FILTERS, date)
    context.commit('setDateFilter', date)
  },
  updatePeriodFilter(context, group){
    StorageService.set(storageKeys.DASHBOARD_EFFICIENCY_CHART, group)
    context.commit('setChartGroup', group)
  }
}
const mutations = {
  setEfficiencyChart(state, data) {
    state.efficiencyChartData = data
  },
  setTopPartners(state, data) {
    state.topPartners = data
  },
  setTopCountries(state, data) {
    state.topCountries = data
  },
  setDateFilter(state, data){
    state.filters.date = data
  },
  setChartGroup(state, data){
    state.group = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
