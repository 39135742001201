export default [
    {
        path: '/currencies',
        name: 'pageCurrenciesList',
        meta: { title: 'currencies.listTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/currencies'),
    },
    {
        path: '/currencies/create',
        name: 'pageCurrenciesCreate',
        meta: { title: 'currencies.createFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/currencies/create'),
    },
    {
        path: '/currencies/:id/edit',
        name: 'pageCurrenciesEdit',
        meta: { title: 'currencies.editFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/currencies/id/edit'),
    },
]
