export default [
  {
    path: '/payment-models',
    name: 'pagePaymentModelsList',
    meta: { title: 'paymentModels.listTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/payment-models'),
  },
  {
    path: '/payment-models/:id/edit',
    name: 'pagePaymentModelsEdit',
    meta: { title: 'paymentModels.editFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/payment-models/id/edit'),
  },
]
