export default [
    {
        path: '/players',
        name: 'pagePlayersList',
        meta: { title: 'players.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['players'] },
        component: () => import('@/pages/players'),
    },
    {
        path: '/players/create',
        name: 'pagePlayersCreate',
        meta: { title: 'players.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['players'] },
        component: () => import('@/pages/players/create'),
    },
    {
        path: '/players/:id/edit',
        name: 'pagePlayersEdit',
        meta: { title: 'players.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['players'] },
        component: () => import('@/pages/players/id/edit'),
    },
]
