import moment from 'moment/moment'
import { DATE_TIME_FORMAT } from '@/constants/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment().subtract(2, 'weeks').format(DATE_TIME_FORMAT)}|${
        moment().format(DATE_TIME_FORMAT,)}`,
      operator: 'bwn',
    },
    siteId: {
      value: '',
      operator: 'in'
    },
    offerPartnerLanding: {
      value: '',
      operator: 'in'
    },
    partner: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    advertiser: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      }
    },
    trafficSource: {
      value: '',
      operator: 'in'
    },
    geoCountry: {
      value: '',
      operator: 'in'
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
