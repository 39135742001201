import partnersAffiliateRepository from "@/repository/generatedRepository/partnersAffiliateRepository";

const actions = {
  async loadPartnerAffiliate(context, filters) {
    const { data } = await partnersAffiliateRepository.list(filters)
    return data
  },
  createPartnerAffiliate(context, payload) {
    return partnersAffiliateRepository.post(payload)
  },
  updatePartnerAffiliate(context, payload) {
    const { id, ...data } = payload
    return partnersAffiliateRepository.put(id, data)
  },
  deletePartnerAffiliate(context, payload) {
    return partnersAffiliateRepository.delete(payload)
  },
}
export default {
  namespaced: true,
  actions,
}
