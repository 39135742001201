export default [
    {
        path: '/offers',
        name: 'pageOffersList',
        meta: { title: 'offer.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['offer_view'] },
        component: () => import('@/pages/offers'),
    },
    {
        path: '/offers/create',
        name: 'pageOffersCreate',
        meta: { title: 'offer.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['offer_view'] },
        component: () => import('@/pages/offers/create'),
    },
    {
        path: '/offers/:id/edit',
        name: 'pageOffersEdit',
        meta: { title: 'offer.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['offer_view'] },
        component: () => import('@/pages/offers/id/edit'),
    },
]
