import store from '@/store'

export function hasPermission(permission) {
  if (!permission) return true  // TODO: temporary condition until all permissions will be set
  const permissions = 'permissions' in permission ? permission.permissions : []
  const roles = 'roles' in permission ? permission.roles : []

  const profilePermissions = store.getters['ProfileModule/profile'].permissions
  const profileRole = store.getters['ProfileModule/profile'].role

  const hasAccessPermission =
    !permissions?.length ? false :
      !Array.isArray(permissions) ||
      permissions.some(permission => profilePermissions.includes(permission))
  const hasAccessRole = !Array.isArray(roles) || roles.includes(profileRole)

  return hasAccessPermission || hasAccessRole
}
