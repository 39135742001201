import SuperRepository from '../superRepository'

export default new (class PartnerGroupsRepository extends SuperRepository {
  baseUri() {
    return 'partner_group'
  }
  createPartnerGroupManager = params => this.httpClient().post(`${this.baseUri()}_manager`, params)
  updatePartnerGroupManager = (id, params) => this.httpClient().put(`${this.baseUri()}_manager/${id}`, params)
  deletePartnerGroupManager = id => this.httpClient().delete(`${this.baseUri()}_manager/${id}`)
})()
