export default [
    {
        path: '/partner-groups',
        name: 'pagePartnerGroupsList',
        meta: { title: 'partnerGroups.listTitle', roles: ['ADMIN', 'TEAM_LEADER'] },
        component: () => import('@/pages/partner-groups'),
    },
    {
        path: '/partner-groups/create',
        name: 'pagePartnerGroupsCreate',
        meta: { title: 'partnerGroups.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'] },
        component: () => import('@/pages/partner-groups/create'),
    },
    {
        path: '/partner-groups/:id/edit',
        name: 'pagePartnerGroupsEdit',
        meta: { title: 'partnerGroups.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'] },
        component: () => import('@/pages/partner-groups/id/edit'),
    },
]
