import { withParams } from 'vuelidate/lib/params'
import i18n from '@/plugins/vue-i18n'
import { isObject } from '@/helpers/functions'

export function validationWithText(errorLocale, validator) {
  let $errorText = ''
  if (isObject(errorLocale)) {
    if (errorLocale.params) {
      Object.entries(errorLocale.params).forEach(([key, value]) => {
        errorLocale.params[key] = i18n.t(value)
      })
    }
    const customData = 'vueData' in errorLocale ? errorLocale.vueData : ''
    $errorText = i18n.t(errorLocale.text, errorLocale.params) + customData
  } else if (typeof errorLocale === 'string') {
    $errorText = i18n.t(errorLocale)
  }
  return withParams({ $errorText }, validator)
}

export function getErrorMsg(item = {}) {
  const errors = []
  if (!item.$dirty) return errors
  Object.entries(item.$params).forEach(([key, params]) => {
    !item[key] && errors.push(params.$errorText)
  })
  return errors
}

export function messageErrorRequest(error, translateKey = {}) {
  const { response } = error
  if (!response) {
    return i18n.t('base.validationErrorServer')
  }
  const status = response.status
  const data = response.data
  switch (status) {
    case 422:
      return error_422(data, translateKey)
    default:
      return data?.message || error.message
  }
}

function error_422(data, translateKey) {
  const { formErrors } = data
  if (Array.isArray(formErrors)) {
    return formErrors.join(',')
  } else if (isObject(formErrors)) {
    return prepareErrorObject(formErrors, translateKey)
  }
  return formErrors
}
function prepareErrorObject(obj, translateKey) {
  return Object.keys(obj)
    .reduce((cur, key) => {
      const fieldName = translateKey[key] || ''
      let itemValue = []

      if (Array.isArray(obj[key])) {
        itemValue = prepareErrorArr(obj[key], key, translateKey)
      } else if (isObject(obj[key])) {
        itemValue = `${fieldName} ${prepareErrorObject(obj[key], translateKey)}`
      } else {
        itemValue = `${fieldName} ${obj[key]}`
      }

      return cur.concat(itemValue)
    }, [])
    .join(',')
}

function prepareErrorArr(arr, key, translateKey) {
  const fieldName = translateKey[key] || ''
  return arr
    .reduce((cur, val) => {
      let itemValue = []

      if (Array.isArray(val)) {
        itemValue = prepareErrorArr(val, key, translateKey)
      } else if (isObject(val)) {
        itemValue = prepareErrorObject(val, translateKey, key)
      } else {
        itemValue = `${fieldName} ${val}`
      }

      return cur.concat(itemValue)
    }, [])
    .join(', ')
}
