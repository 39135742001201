import SuperRepository from '../superRepository'

export default new (class PartnersEventRepository extends SuperRepository {
  baseUri() {
    return 'partner_event_group'
  }
  createEventGroup = async id =>
    this.httpClient().post(`${this.baseUri()}/${id}/partner`)
  updateEventGroup = async ({ id, ...params }) =>
    this.httpClient().put(`${this.baseUri()}/${id}`, params)
})()
