export default [
  {
    path: '/products',
    name: 'pageProductsList',
    meta: { title: 'products.listTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/products'),
  },
  {
    path: '/products/create',
    name: 'pageProductsCreate',
    meta: { title: 'products.createFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/products/create'),
  },
  {
    path: '/products/:id/edit',
    name: 'pageProductsEdit',
    meta: { title: 'products.editFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/products/id/edit'),
  },
]
