
export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    enabled: 1,
  },
  cfFilter: {
    0: {
      leaderPartnerGroups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      managersGroup: {
        group: {
          id: {
            value: '',
            operator: 'in',
          }
        }
      }
    }
  },
  sortBy: ['enabled', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export default function customUserLabel(option) {
  return option.name === undefined ? undefined :
    `${option.name} ${option.lastName === 'null' || option.lastName === null ? '' : option.lastName}`
}
export function customManagerUserLabel(option) {
  if(option.name || option.lastName) return option?.name === undefined ? undefined :
      `${option.name} ${option.lastName === 'null' || option.lastName === null ? '' : option.lastName}`
  return option?.manager?.name === undefined ? undefined :
    `${option.manager.name} ${option.manager.lastName === 'null' || option.manager.lastName === null ? '' : option.manager.lastName}`
}