export default [
    {
        path: '/landings',
        name: 'pageLandingsList',
        meta: { title: 'landings.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['landing_view'] },
        component: () => import('@/pages/landings'),
    },
    {
        path: '/landings/create',
        name: 'pageLandingsCreate',
        meta: { title: 'landings.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['landing_view'] },
        component: () => import('@/pages/landings/create'),
    },
    {
        path: '/landings/:id/edit',
        name: 'pageLandingsEdit',
        meta: { title: 'landings.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['landing_view'] },
        component: () => import('@/pages/landings/id/edit'),
    },
]
