export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    advertiser: {
      id: {
        value: '',
        operator: 'in'
      }
    },
    postbackPartner: {
      id: {
        value: '',
        operator: 'in'
      }
    },
    trafficSource: {
      id: {
        value: '',
        operator: 'in'
      }
    },
    createdAt: {
      value: '',
      operator: 'bwn',
    },
    player: {
      value: '',
      operator: 'in'
    },
    event: {
      value: '',
      operator: 'in'
    },
    offerPartnerLanding: null
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
