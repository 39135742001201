export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        createdAt: {
            value: null,
            operator: 'bwn',
        },
        active: 1,
        offer: null,
        partner: null,
        trafficSource: null,
        stream: null,
    },
    sortBy: ['active', 'id'],
    sortDesc: [true, true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
