export default [
    {
        path: '/users',
        name: 'pageUsersList',
        meta: { title: 'users.listTitle', roles: ['ADMIN', 'TEAM_LEADER'] },
        component: () => import('@/pages/users'),
    },
    {
        path: '/users/create',
        name: 'pageUsersCreate',
        meta: { title: 'users.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'] },
        component: () => import('@/pages/users/create'),
    },
    {
        path: '/users/:id/edit',
        name: 'pageUsersEdit',
        meta: { title: 'users.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'] },
        component: () => import('@/pages/users/id/edit'),
    },
]