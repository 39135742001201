export default [
    {
        path: '/offer-partners',
        name: 'pageOfferPartnersList',
        meta: { title: 'offerPartners.listTitle' },
        component: () => import('@/pages/offer-partners'),
    },
    {
        path: '/offer-partners/create',
        name: 'pageOfferPartnersCreate',
        meta: { title: 'offerPartners.createFormTitle' },
        component: () => import('@/pages/offer-partners/create'),
    },
    {
        path: '/offer-partners/:id/edit',
        name: 'pageOfferPartnersEdit',
        meta: { title: 'offerPartners.editFormTitle' },
        component: () => import('@/pages/offer-partners/id/edit'),
    },
]
