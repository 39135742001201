export default [
    {
        path: '/players-reports-api',
        name: 'pagePlayersReportsApiList',
        meta: { title: 'playersReportsApi.listTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/players-reports-api'),
    },
    {
        path: '/players-reports-api/create',
        name: 'pagePlayersReportsApiCreate',
        meta: { title: 'playersReportsApi.createFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/players-reports-api/create'),
    },
    {
        path: '/players-reports-api/:id/edit',
        name: 'pagePlayersReportsApiEdit',
        meta: { title: 'playersReportsApi.editFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/players-reports-api/id/edit'),
    }
]
