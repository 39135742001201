import SuperRepository from '../superRepository'

export default new (class PlayersRepository extends SuperRepository {
  baseUri() {
    return 'player'
  }
  geoCountry = async() => this.httpClient().get(`${this.baseUri()}/geo_country`)
  promocode = async() => this.httpClient().get(`${this.baseUri()}/promocode`)
  import = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/import`, data, params)
  importUpdate = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/import?update_reg_date=1`, data, params)
  synchronize = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/synchronize?date=${data}`, params)
})()
