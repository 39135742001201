import SuperRepository from '../superRepository'

export default new (class PartnerGroupRepository extends SuperRepository {
  baseUri() {
    return 'partner_partner_group'
  }
  updatePartnerGroup = async ({ id, ...params }) =>
    this.httpClient().put(`${this.baseUri()}/${id}`, params)
  updatePartnerGroupManual = async ({ id, ...params }) =>
    this.httpClient().post(`${this.baseUri()}/${id}/parse_sheet`, params)
})()
