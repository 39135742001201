export default [
    {
        path: '/roles',
        name: 'pageRolesList',
        meta: { title: 'roles.listTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/roles'),
    },
    {
        path: '/roles/create',
        name: 'pageRolesCreate',
        meta: { title: 'roles.createFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/roles/create'),
    },
    {
        path: '/roles/:id/edit',
        name: 'pageRolesEdit',
        meta: { title: 'roles.editFormTitle', roles: ['ADMIN'] },
        component: () => import('@/pages/roles/id/edit'),
    },
]