import SuperRepository from '../superRepository'

export default new (class PartnersSpendsRepository extends SuperRepository {
  baseUri() {
    return 'partner_spend'
  }
  import = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/import`, data, params)
  getTableData = params => this.httpClient().get(`${this.baseUri()}/table`, params)
  updateTableData = params => this.httpClient().putQuery(`${this.baseUri()}/update_table`, params)
})()
