import { prepareParams, paramsSerializerQs } from '@/helpers/api'
import axiosApiInstance from '@/plugins/axios'

const ApiService = {
  request(url, params, cfg) {
    const config = { params: prepareParams(params), paramsSerializer: paramsSerializerQs, ...cfg }
    return axiosApiInstance.get(url, config)
  },
  get(url, params, customConfig = {}) {
    const { loc, signal, ...rest } = params || {}
    const config = {
      params: prepareParams(rest),
      paramsSerializer: paramsSerializerQs,
      signal,
      ...customConfig,
    }
    config.loc = loc
    return axiosApiInstance.get(url, config)
  },
  getFile(url, params) {
    const config = {
      params: prepareParams(params),
      responseType: 'arraybuffer',
    }
    return axiosApiInstance.get(url, config)
  },
  postFile(url, data, params = {}) {
    const config = { ...params, headers: { 'Content-Type': 'multipart/form-data' } }
    return axiosApiInstance.post(url, data, config)
  },
  post(url, payload, params) {
    if (payload && 'signal' in payload) {
      const {signal, ...data} = payload
      return axiosApiInstance.post(`${url}`, data, {...params, signal})
    } else {
      return axiosApiInstance.post(`${url}`, payload, params)
    }
  },
  update(url, slug, params) {
    return axiosApiInstance.put(`${url}/${slug}`, params)
  },
  put(url, params) {
    return axiosApiInstance.put(`${url}`, params)
  },
  putQuery(url, query) {
    return axiosApiInstance.put(`${url}`, null, {params: {...query}})
  },
  patch(url, data, params) {
    return axiosApiInstance.patch(`${url}`, data, params)
  },
  delete(url, data) {
    return axiosApiInstance.delete(url, { ...data })
  },
}
export default ApiService
