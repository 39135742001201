<template>
  <tr :class="getItemClass(group)">
    <td :class="getHeaderClasses(headers[0])" :style="getCellStyles(headers[0])">
      <div class="flex-nowrap d-flex align-start py-1 container-header">
        <v-icon color="#AEAEAE" class="mr-4" style="margin-top: -2px" @click="onOpenGroup" v-if="isExpandArrowShown">
          {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <div v-else-if="!isExpandArrowShown && hasExpandPanel" class="ml-10"></div>
        <div v-else></div>

        <slot :name="`header.${expandColumnName}`" :item="group" :value="group[expandColumnName]">
          <span>
            {{ group[expandColumnName] }}
          </span>
        </slot>

        <v-icon
          v-if="innerSort"
          size="small"
          color="black lighten-2"
          class="ml-1 mt-1 cursor-pointer"
          @click="onChangeSort"
        >
          {{ isSortable ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
        </v-icon>
      </div>
    </td>

    <td
      v-for="(header, index) in getGroupHeaders(headers)"
      :key="`group-header-cell-${index}`"
      :class="getHeaderClasses(header)"
      :style="getCellStyles(header)"
    >
      <slot :name="`header.${header.value}`" :item="group" :value="group[header.value]">
        {{ group[header.value] }}
      </slot>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'UiExpandHeader',
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    innerSort: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: Function,
      default: () => '',
    },
    isExpandArrowShown: {
      type: Boolean,
      default: true
    },
    hasExpandPanel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    expandColumnName() {
      return this.headers[0].value
    },
  },
  methods: {
    getItemClass(item){
      return this.itemClass ? this.itemClass(item) : ''
    },
    getCellStyles(header) {
      const defaultStyles = {
        background: '#fff',
        borderBottom: '1px var(--v-black-lighten6) solid',
      }

      if (!header.width) {
        return defaultStyles
      }

      return {
        ...defaultStyles,
        width: header.width,
        minWidth: header.width,
        maxWidth: header.width,
      }
    },
    getHeaderClasses(header) {
      const hasFixedEls = header.fixed && header.fixed.side
      return [
        { 'v-data-table__divider': header.divider },
        { 'fixed-col': hasFixedEls },
        `${hasFixedEls ? header.fixed.side : ''}`,
        header.class,
      ]
    },
    getGroupHeaders(headers) {
      return headers.slice(1, headers.length)
    },
    onOpenGroup() {
      this.$emit('onOpen', !this.isOpen)
    },
    onChangeSort() {
      this.$emit('onChangeSort', !this.isSortable)
    },
  },
}
</script>
<style lang="scss" scoped>
.container-header {
  position: relative;
}
</style>
