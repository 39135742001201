import i18n from "@/plugins/vue-i18n";

function convertTreeToList(arr) {
  return arr.reduce((cur, item) => {
    const { items, ...rest } = item

    if (items) {
      cur = cur.concat(convertTreeToList(items))
    } else {
      cur.push(rest)
    }

    return cur
  }, [])
}

const managerPermissions = [
  { name: i18n.tc('dashboard.listTitle'), key: 'dashboard', dependsOn: [] },
  { name: i18n.tc('offer.listTitle'), key: '644b7cfb4c052', items: [
      { name: i18n.tc('permissions.viewing'), key: 'offer_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'offer_edit', dependsOn: ['offer_view'] },
    ]},
  { name: i18n.tc('partners.listTitle'), key: '644b7cf45ad2w9', items: [
      { name: i18n.tc('permissions.viewing'), key: 'partners_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'partners_edit', dependsOn: ['partners_view'] },
      { name: i18n.tc('permissions.managersAccess'), key: 'manager_access', dependsOn: ['partners_edit'] },
    ]},
  { name: i18n.tc('partnersSpends.listTitle'), key: '644b7cfb4c0ff', items: [
      { name: i18n.tc('permissions.viewing'), key: 'partners_spend_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'partners_spend_edit', dependsOn: ['partners_spend_view'] },
    ]},
  { name: i18n.tc('players.listTitle'), key: 'players', dependsOn: [] },
  { name: i18n.tc('landings.listTitle'), key: '644b7cfb4bff9', items: [
      { name: i18n.tc('permissions.viewing'), key: 'landing_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'landing_edit', dependsOn: ['landing_view'] },
    ]},
  { name: i18n.tc('trafficSources.listTitle'), key: '644b7cfb4c007', items: [
      { name: i18n.tc('permissions.viewing'), key: 'traffic_source_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'traffic_source_edit', dependsOn: ['traffic_source_view'] },
    ]},
  { name: i18n.tc('clicks.listTitle'), key: 'click', dependsOn: [] },
  { name: i18n.tc('generalReport.listTitle'), key: 'general_report', dependsOn: [] },
  { name: i18n.tc('cohortAnalysis.listTitle'), key: 'cohort_analysis_view', dependsOn: [] },
  { name: i18n.tc('partnerReport.listTitle'), key: 'partner_report', dependsOn: [] },
]

const partnerPermissions = [
  { name: i18n.tc('dashboard.listTitle'), key: 'dashboard', dependsOn: [] },
  { name: i18n.tc('offer.listTitle'), key: '644b7cfb4c052', items: [
      { name: i18n.tc('permissions.viewing'), key: 'offer_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'offer_edit', dependsOn: ['offer_view'] },
    ]},
  { name: i18n.tc('partnersSpends.listTitle'), key: '644b7cfb4c0ff', items: [
      { name: i18n.tc('permissions.viewing'), key: 'partners_spend_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'partners_spend_edit', dependsOn: ['partners_spend_view'] },
    ]},
  { name: i18n.tc('players.listTitle'), key: 'players', dependsOn: [] },
  { name: i18n.tc('landings.listTitle'), key: '644b7cfb4bff9', items: [
      { name: i18n.tc('permissions.viewing'), key: 'landing_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'landing_edit', dependsOn: ['landing_view'] },
    ]},
  { name: i18n.tc('trafficSources.listTitle'), key: '644b7cfb4c007', items: [
      { name: i18n.tc('permissions.viewing'), key: 'traffic_source_view', dependsOn: [] },
      { name: i18n.tc('permissions.editing'), key: 'traffic_source_edit', dependsOn: ['traffic_source_view'] },
    ]},
  { name: i18n.tc('clicks.listTitle'), key: 'click', dependsOn: [] },
  { name: i18n.tc('generalReport.listTitle'), key: 'general_report', dependsOn: [] },
]

const state = {
  permissionTree: [],
  permissionList: [],
}

const getters = {
  permissionTree: state => state.permissionTree || [],
  permissionList: state => state.permissionList || [],
}

const actions = {
  async loadPermissionTree(context, role) {
    const data = role === 'MANAGER' ?
      managerPermissions : partnerPermissions;
    context.commit('setPermissionTree', data)
    context.commit('setPermissionList', convertTreeToList(data))
    return { permissionTree: data }
  },
}

const mutations = {
  setPermissionTree(state, payload) {
    state.permissionTree = payload
  },
  setPermissionList(state, payload) {
    state.permissionList = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
