import settingRepo from '@/repository/generatedRepository/settingsRepository'

const state = {
  settingList: [],
  loadingData: false,
}

const getters = {
  settingList: state => state.settingList,
  loadingData: state => state.loadingData,
}

const actions = {
  async loadSettingList(context) {
    context.commit('setLoadingData', true)
    const { data } = await settingRepo.list({
      pagination: { limit: -1 },
      order: { active: 'desc' },
    })

    context.commit('setSetting', data)
    context.commit('setLoadingData', false)

    return { settingList: data }
  },

  async loadSetting(context, id) {
    const { data } = await settingRepo.get(id)
    return data
  },
  createSetting(context, payload) {
    return settingRepo.post(payload)
  },
  updateSetting(context, payload) {
    const { id = 1, ...data } = payload
    context.commit('setSetting', data)
    return settingRepo.put(id, data)
  },
}

const mutations = {
  setSetting(state, data) {
    state.settingList = data
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
