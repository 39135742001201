export default [
  {
    path: '/advertisers',
    name: 'pageAdvertisersList',
    meta: { title: 'advertisers.listTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/advertisers'),
  },
  {
    path: '/advertisers/create',
    name: 'pageAdvertisersCreate',
    meta: { title: 'advertisers.createFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/advertisers/create'),
  },
  {
    path: '/advertisers/:id/edit',
    name: 'pageAdvertisersEdit',
    meta: { title: 'advertisers.editFormTitle', roles: ['ADMIN'] },
    component: () => import('@/pages/advertisers/id/edit'),
  },
]
