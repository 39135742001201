<template>
  <tbody :class="tbodyClass">
    <ui-expand-header
      :headers="headers"
      :inner-sort="innerSort"
      :is-open="isOpen"
      :is-sortable="isSortable"
      :has-expand-panel="hasExpandPanel"
      :is-expand-arrow-shown="!!isExpandArrowShown"
      :item-class="headerClass"
      :group="group"
      @onOpen="onOpenGroup($event, group)"
      @onChangeSort="onChangeSort"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ item }">
        <slot
          v-if="$scopedSlots[`header.${header.value}`]"
          :name="`header.${header.value}`"
          :item="item"
          :value="item[header.value]"
          :header="header"
        >
          {{ item[header.value] }}
        </slot>

        <slot
          v-else
          :name="`item.${header.value}`"
          :item="item"
          :value="getValue(item, header.value)"
        >
          {{ getValue(item, header.value) }}
        </slot>
      </template>
    </ui-expand-header>
    <template v-if="isOpen && !insertThirdGroup && !insertFourthGroup">
      <tr v-if="showInnerHeaders" class="inner-table-headers">
        <td v-for="(header, index) in filteredHeaders" :key="index">
          {{header.text}}
        </td>
      </tr>
      <ui-expand-content
        v-for="content in sortedItems"
        :key="content.uuid"
        :headers="filteredHeaders"
        :item-class="itemClass"
        :item="content"
      >
        <template v-for="header in filteredHeaders" v-slot:[`item.${header.value}`]="{ item }">
          <slot :name="`item.${header.value}`" :item="item" :value="getValue(item, header.value)">
            {{ getValue(item, header.value) }}
          </slot>
        </template>
      </ui-expand-content>
    </template>
    <template v-if="isOpen && insertThirdGroup">
      <template v-for="(subItem, subIndex) in items">
        <ui-double-expand-header
          :key="subIndex"
          :headers="headers"
          :inner-sort="innerSort"
          :is-open="openedSecondGroupsId.includes(subItem.id)"
          :is-sortable="isSortable"
          :has-expand-panel="hasExpandPanel"
          :is-expand-arrow-shown="hasInnerExpandArrow(group)"
          :item-class="headerClass"
          :group="subItem"
          @onOpen="onOpenSubGroup($event, subItem)"
          @onChangeSort="onChangeSort"
        >
          <template v-for="subheader in headers" v-slot:[`subheader.${subheader.value}`]="{ item }">
            <slot
              v-if="$scopedSlots[`subheader.${subheader.value}`]"
              :name="`subheader.${subheader.value}`"
              :item="item"
              :value="item[subheader.value]"
              :header="subheader"
            >
              {{ item[subheader.value] }}
            </slot>

            <slot
              v-else
              :name="`item.${subheader.value}`"
              :item="item"
              :value="getValue(item, subheader.value)"
            >
              {{ getValue(item, subheader.value) }}
            </slot>
          </template>
        </ui-double-expand-header>
        <template v-if="openedSecondGroupsId.includes(subItem.id)">
          <ui-expand-content
            v-for="content in subItem.partnersPrep"
            :key="content.uuid"
            :headers="filteredHeaders"
            :item="content"
            :item-class="itemClass"
          >
            <template v-for="header in filteredHeaders" v-slot:[`item.${header.value}`]="{ item }">
              <slot :name="`item.${header.value}`" :item="item" :value="getValue(item, header.value)">
                {{ getValue(item, header.value) }}
              </slot>
            </template>
          </ui-expand-content>
        </template>
      </template>
    </template>
    <template v-if="isOpen && insertFourthGroup">
      <template v-for="subItem in sortedSubItems(items)">
        <ui-second-expand-header
          :key="subItem.id"
          :headers="headers"
          :inner-sort="innerSort"
          :is-open="openedSecondGroupsId.includes(subItem.id)"
          :is-sortable="isSortable"
          :is-expand-arrow-shown="!!filters.thirdGroupBy && 'third_group' in subItem"
          :item-class="headerClass"
          :group="subItem"
          :has-background-color="insertFourthGroup"
          @onOpen="onOpenSubGroup($event, subItem)"
          @onChangeSort="onChangeSort"
        >
          <template v-for="subheader in headers" v-slot:[`subheader.${subheader.value}`]="{ item }">
            <slot
              v-if="$scopedSlots[`subheader.${subheader.value}`]"
              :name="`subheader.${subheader.value}`"
              :item="item"
              :value="item[subheader.value]"
              :header="subheader"
            >
              {{ item[subheader.value] }}
            </slot>

            <slot
              v-else
              :name="`item.${subheader.value}`"
              :item="item"
              :value="getValue(item, subheader.value)"
            >
              {{ getValue(item, subheader.value) }}
            </slot>
          </template>
        </ui-second-expand-header>

        <template v-if="openedSecondGroupsId.includes(subItem.id) && filters.thirdGroupBy">
          <template v-for="content in sortedSubItems(subItem.third_group)">
            <ui-third-expand-header
              :key="content.id"
              :headers="headers"
              :inner-sort="innerSort"
              :is-open="openedThirdGroupsId.includes(content.id)"
              :is-sortable="isSortable"
              :is-expand-arrow-shown="!!filters.fourthGroupBy && 'fourth_group' in content"
              :item-class="headerClass"
              :has-background-color="insertFourthGroup"
              :group="content"
              @onOpen="onOpenThirdGroup($event, content)"
              @onChangeSort="onChangeSort"
            >
              <template v-for="subheader in headers" v-slot:[`subheader.${subheader.value}`]="{ item }">
                <slot
                  v-if="$scopedSlots[`subheader.${subheader.value}`]"
                  :name="`subheader.${subheader.value}`"
                  :item="item"
                  :value="item[subheader.value]"
                  :header="subheader"
                >
                  {{ item[subheader.value] }}
                </slot>

                <slot
                  v-else
                  :name="`item.${subheader.value}`"
                  :item="item"
                  :value="getValue(item, subheader.value)"
                >
                  {{ getValue(item, subheader.value) }}
                </slot>
              </template>
            </ui-third-expand-header>

            <template v-if="openedThirdGroupsId.includes(content.id) && filters.fourthGroupBy">
              <template v-for="subContent in sortedSubItems(content.fourth_group)">
                <ui-expand-content
                  :key="subContent.id"
                  :headers="filteredHeaders"
                  :item="subContent"
                  :has-background-color="insertFourthGroup"
                  :item-class="itemClass"
                >
                  <template v-for="header in filteredHeaders" v-slot:[`item.${header.value}`]="{ item }">
                    <slot :name="`item.${header.value}`" :item="item" :value="getValue(item, header.value)">
                      {{ getValue(item, header.value) }}
                    </slot>
                  </template>
                </ui-expand-content>
              </template>
            </template>
          </template>
        </template>
      </template>
    </template>
    <slot name="append" />
  </tbody>
</template>
<script>
import UiExpandHeader from '@/components/ui/framework/general/UIExpandGroup/UIExpandHeader.vue'
import UiExpandContent from '@/components/ui/framework/general/UIExpandGroup/UIExpandContent.vue'
import {get, isString, orderBy} from 'lodash'
import { randomString, splitArr } from '@/helpers/functions'
import { alphabet } from '@/constants/alphabet'
import UiDoubleExpandHeader from "@/components/ui/framework/general/UIDoubleExpandGroup/UIDoubleExpandHeader.vue";
import UiSecondExpandHeader from "@/components/ui/framework/general/UIMultiExpandGroup/UISecondExpandHeader.vue";
import UiThirdExpandHeader from "@/components/ui/framework/general/UIMultiExpandGroup/UIThirdExpandHeader.vue";
export default {
  name: 'UiExpandGroup',
  components: {
    UiThirdExpandHeader,
    UiSecondExpandHeader,
    UiDoubleExpandHeader,
    UiExpandHeader,
    UiExpandContent,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    openedGroups: {
      type: Array,
      default: () => ([]),
    },
    openedSubGroups: {
      type: Array,
      default: () => ([]),
    },
    openedThirdGroups: {
      type: Array,
      default: () => ([]),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    innerHeaders: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    fixedColsCount: {
      type: Number,
      default: () => 0,
    },
    hasFixedCols: {
      type: Boolean,
      default: () => false,
    },
    innerSort: {
      type: Boolean,
      default: false,
    },
    valueMutator: {
      type: Function,
      default: () => null,
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
    closeOnDataChange: {
      type: Boolean,
      default: true
    },
    showInnerHeaders: {
      type: Boolean,
      default: false
    },
    insertThirdGroup: {
      type: Boolean,
      default: false
    },
    insertFourthGroup: {
      type: Boolean,
      default: false
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    hideExpandArrow: {
      type: Boolean,
      default: false
    },
    hasExpandPanel: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: Function,
      default: null,
    },
    itemClass: {
      type: Function,
      default: () => '',
    }
  },
  data() {
    return {
      isOpen: false,
      isSortable: false,
      filteredHeaders: [],
      currentIterItem: null,
      openedItemGroups: [],
      openedSecondGroupsId: [],
      openedThirdGroupsId: [],
    }
  },
  computed: {
    isExpandArrowShown(){
      return !this.hideExpandArrow && this.items.length
    },
    innerSortKey() {
      return this.options?.sortBy[0] || this.headers[0].value
    },
    sortedItems() {
      const orders = (this.options?.sortDesc[0] || this.isSortable) ? 'desc' : 'asc'
      return this.disableSort ? this.items : orderBy(this.items, [this.innerSortKey], [orders])
    },
    tbodyClass() {
      return randomString(alphabet, 8).join('')
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val && this.hasFixedCols) {
          setTimeout(() => {
            this.fixCols('.fixed-col.right.expanded-col')
          }, 0)
        }
      },
    },
    items: {
      handler() {
        if(this.closeOnDataChange){
          this.isOpen = false
        }
      },
    },
    columnsToShow(data) {
      this.setHeaders(data)
      this.detachCols()
      this.$nextTick(() => {
        this.fixCols()
      })
    },
    openedGroups: {
      handler(newVal){
        this.isOpen = newVal.includes(this.group.id)
      },
      immediate: true
    },
    openedSubGroups: {
      handler(newVal){
        this.openedSecondGroupsId = [...newVal]
      },
      immediate: true
    },
    openedThirdGroups: {
      handler(newVal){
        this.openedThirdGroupsId = [...newVal]
      },
      immediate: true
    },
  },
  created() {
    this.setHeaders(this.columnsToShow)
  },
  mounted() {
    if (this.hasFixedCols) {
      this.fixCols()
    }
  },
  methods: {
    hasInnerExpandArrow(item){
      return item.title !== this.$t('generalReport.table.notUsedSpend')
    },
    sortedSubItems(arr) {
      const monthOrder = [
        'january', 'february', 'march',
        'april', 'may', 'june', 'july',
        'august', 'september', 'october',
        'november', 'december'
      ];
      const getMonthIndex = month => monthOrder.indexOf(month.toLowerCase())
      const getWeekStart = (weekRange) => this.$moment(weekRange.split(' - ')[0], 'DD.MM.YYYY');
      function sortGroupList(group, sortKey) {
        const value = get(group, sortKey)
        if (sortKey.includes('month')) {
          return value && isString(value) ? getMonthIndex(value) : -1;
        } else if (sortKey.includes('week')) {
          return value && isString(value) ? getWeekStart(value) : this.$moment.invalid();
        } else {
          return value && isString(value) ? value.toLowerCase() : value;
        }
      }
      const sortKey = this.innerSortKey === 'groups.month' ? 'groups.month_title' : this.innerSortKey
      const orders = (this.options?.sortDesc[0] || this.isSortable) ? 'desc' : 'asc'
      const sortFunc = group => sortGroupList(group, sortKey)
      return this.disableSort ? arr : orderBy(arr, sortFunc, orders)
    },
    fixCols(classes = '.fixed-col.right') {
      const rows = splitArr(
        document.querySelectorAll(`.${this.tbodyClass} ${classes}`),
        this.fixedColsCount,
        true,
      )
      rows.forEach(row => {
        let prevElWidth = 0
        row.forEach((col, index) => {
          const rect = col.getBoundingClientRect()
          prevElWidth += rect.width

          if (index === 0) {
            col.style.right = '0px'
            return
          }

          let offset = rect.width
          let offsetNext = 0

          if (index > 0) offsetNext = Math.abs(offset - prevElWidth)
          col.style.right = offsetNext + 'px'
        })
      })
    },
    detachCols() {
      document.querySelectorAll(`.${this.tbodyClass} .fixed-col.right`).forEach(item => {
        item.style.right = null
      })
    },
    getValue(item, key) {
      const value = get(item, key)

      if (this.valueMutator) {
        return this.valueMutator({
          value,
          item,
          key,
        })
      }

      return value
    },
    onOpenGroup(isOpen, item) {
      this.isOpen = isOpen
      if (!isOpen && item.groups?.length) {
        item.groups?.forEach((group) => {
          this.openedSecondGroupsId = this.openedSecondGroupsId.filter(el => el !== group.id)
        })
      }
      this.$emit('openedGroup', isOpen, item.id)
    },
    onOpenSubGroup(isOpen, item) {
      if (isOpen) this.openedSecondGroupsId.push(item.id)
      if (!isOpen) this.openedSecondGroupsId = this.openedSecondGroupsId.filter(el => el !== item.id)
      this.$emit('openedSubGroup', isOpen, item.id)
    },
    onOpenThirdGroup(isOpen, item) {
      if (isOpen) this.openedThirdGroupsId.push(item.id)
      if (!isOpen) this.openedThirdGroupsId = this.openedThirdGroupsId.filter(el => el !== item.id)
      this.$emit('openedThirdGroup', isOpen, item.id)
    },
    onChangeSort(isSortable) {
      this.isSortable = isSortable
    },
    setHeaders(selectedHeaders = []) {
      let currentHeaders = this.innerHeaders.length ? this.innerHeaders : this.headers
      if (!selectedHeaders.length) {
        this.filteredHeaders = [...currentHeaders]
        return
      }

      const colIDs = selectedHeaders.map(selectedItem => selectedItem.value)

      this.filteredHeaders = currentHeaders.filter(item => {
        return !colIDs.includes(item.value)
      })
    },
  },
}
</script>

<style>
tr.inner-table-headers td {
  color: #AEAEAE!important;
  font-weight: 400!important;
  font-size: 12px!important;
  line-height: 20px!important;
}
</style>
