import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/PlayersReports/helpers'
import reportsRepository from '@/repository/generatedRepository/reportsRepository'
import playersRepository from "@/repository/generatedRepository/playersRepository";
import TableOptions from '@/models/TableOptions'
import { getSortedItems, omitEmptyDeepFilters, prepareFilters} from '@/helpers/filters'
import playerReportsRepository from "@/repository/generatedRepository/playerReportsRepository";
const { saveAs } = require('file-saver')

const state = {
  list: [],
  total: {},
  listApi: [],
  reportsList: [],
  filters: StorageService.get(storageKeys.PLAYERS_REPORTS_FILTERS) || { ...defaultFilters },
  filtersApi: StorageService.get(storageKeys.PLAYERS_REPORTS_API_FILTERS) || { ...defaultFilters },
  filtersPlayer: StorageService.get(storageKeys.PLAYER_REPORT_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
  tableOptionsApi: TableOptions.defaultTableOptions(),
  tableOptionsPlayer: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  filtersApi: state => state.filtersApi,
  filtersPlayer: state => state.filtersPlayer,
  tableOptions: state => state.tableOptions,
  tableOptionsApi: state => state.tableOptionsApi,
  tableOptionsPlayer: state => state.tableOptionsPlayer,
  list: state => state.list,
  total: state => state.total,
  listApi: state => state.listApi,
  reportsList: state => state.reportsList,
  reportsListPrepared: state =>
    getSortedItems(state.reportsList, {
      sortBy: state.filtersPlayer.sortBy,
      sortDesc: state.filtersPlayer.sortDesc,
    }),
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.PLAYERS_REPORTS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        type: 'file'
      },
      cfFilter: appliedCfFilters,
      signal
    })
    const { data } = await reportsRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async loadDataApi(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setApiFilters', filters)
    StorageService.set(storageKeys.PLAYERS_REPORTS_API_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        type: 'api'
      },
      cfFilter: appliedCfFilters,
      signal,
      isFilter: 1
    })
    const { data } = await reportsRepository.list(preparedFilters)

    context.commit('setApiList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async importFile(context, payload) {
    const { formData } = payload
    return await playersRepository.import(formData, { responseType: 'blob' })
  },
  async importFileUpdate(context, payload) {
    const { formData } = payload
    return await playersRepository.importUpdate(formData, { responseType: 'blob' })
  },
  async syncApi(context, payload) {
    return await playersRepository.synchronize(payload)
  },
  async getError(context, payload){
    const { formData } = payload
    return await playersRepository.import(formData)
  },
  async downloadLogFile(context, payload){
    const blob = new Blob([payload.data], { type: payload.headers['content-type'] })
    const filename = 'log_file.xlsx'
    saveAs(blob, filename)
    return payload
  },
  async loadItem(context, id) {
    const { data } = await reportsRepository.get(id)
    const preparedFilters = prepareFilters({
      ...state.filtersPlayer,
      filter: {
        report: {
          id
        }
      },
    })

    const { data: tableData } = await playerReportsRepository.list(preparedFilters)
    tableData.items.forEach(el => el.id = el.player.id)

    context.commit('setReportsList', {
      data: tableData,
      filters: state.filtersPlayer,
    })

    return data
  },
  async filterList(context, filters){
    const {id, ...prepFilters} = filters
    const appliedFilters = omitEmptyDeepFilters(prepFilters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(prepFilters.cfFilter)

    const preparedFilters = prepareFilters({
      ...prepFilters,
      filter: {
        ...appliedFilters,
        report: {
          id
        }
      },
      cfFilter: appliedCfFilters,
    })

    const { data } = await playerReportsRepository.list(preparedFilters)
    data.items.forEach(el => el.id = el.player.id)

    context.commit('setReportsList', {
      data,
      filters: state.filtersPlayer,
    })
  },
  createItem(context, payload) {
    return reportsRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return reportsRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return reportsRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.PLAYERS_REPORTS_FILTERS)
  },
  async getReportFile(context, {fileName = 'report.txt', ...payload}){
    const response = await reportsRepository.getFile(payload)
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    saveAs(blob, fileName)
    return response
  },
  resetSearchFilter({commit}){
    commit('clearSearch')
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.PLAYERS_REPORTS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.total = {...data.total}
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setApiList(state, { data, filters }) {
    state.listApi = [...data.items]
    state.tableOptionsApi = new TableOptions(data.pagination, filters)
  },
  setReportsList(state, { data, filters }) {
    state.reportsList = [...data.items]
    state.tableOptionsPlayer = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setApiFilters(state, filters) {
    state.filtersApi = { ...filters }
  },
  setFiltersPlayer(state, filters) {
    state.filtersPlayer = { ...filters }
  },
  clearSearch(state) {
    state.tableOptionsApi.search = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
