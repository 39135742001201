export default [
    {
        path: '/traffic-sources',
        name: 'pageTrafficSourcesList',
        meta: { title: 'trafficSources.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['traffic_source_view'] },
        component: () => import('@/pages/traffic-sources'),
    },
    {
        path: '/traffic-sources/create',
        name: 'pageTrafficSourcesCreate',
        meta: { title: 'trafficSources.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['traffic_source_view'] },
        component: () => import('@/pages/traffic-sources/create'),
    },
    {
        path: '/traffic-sources/:id/edit',
        name: 'pageTrafficSourcesEdit',
        meta: { title: 'trafficSources.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['traffic_source_view'] },
        component: () => import('@/pages/traffic-sources/id/edit'),
    },
]
