<template>
  <v-pagination
    :value="value.page"
    :length="totalItems"
    :total-visible="7"
    color="primary"
    class="ui-pagination"
    @input="onInput"
  ></v-pagination>
</template>
<!--class="ui-pagination pt-4 pr-2"-->
<script>
export default {
  name: 'ui-pagination',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    totalItems: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onInput(v) {
      this.$emit('input', Object.assign({}, this.value, { page: v }))
    },
  },
}
</script>

<style lang="scss">
.ui-pagination {
  height: 30px;
  display: flex;
  align-items: center;

  .v-pagination__item {
    box-shadow: none;
    transition: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--v-black-darken1) !important;
  }

  .theme--light.v-pagination .v-pagination__navigation {
    background: #EBF5FF;
  }

  .v-pagination__navigation {
    box-shadow: none;

    .theme--light.v-icon {
      color: var(--v-primary-base);
      font-size: 16px;
    }
  }

  .v-pagination__item--active {
    color: white !important;
  }

  .v-pagination__navigation:last-child {
    margin-right: 0;
    margin-left: 0;
  }

  .v-pagination__navigation--disabled {
    background: transparent !important;

    .theme--light.v-icon {
      color: var(--v-black-lighten2);
    }
  }
}
</style>
