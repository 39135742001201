<template>
  <tr class="black lighten-7 sticky-footer">
    <td
      class="text-start title-table left"
      :class="{ 'fixed-col': fixedTotal, 'divider': divider }"
      style="background: #f7f7f7"
    >
      <span class="text-body-1 black--text text--darken-3">
        {{ $t('base.total') }}
      </span>
    </td>

    <td
      v-for="header in filteredHeaders"
      :key="header.value"
      class="text--base"
      style="background: #f7f7f7"
      :class="getCellClasses(header)"
    >
      <slot
        :name="`item.${header.value}`"
        :item="totalItem"
        :value="getValue(totalItem, header.value)"
      >
        {{ getValue(totalItem, header.value) }}
      </slot>
    </td>
  </tr>
</template>
<script>
import { get } from 'lodash'

export default {
  name: 'UITotalFooter',
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
    fixedTotal: {
      type: Boolean,
      default: false,
    },
    totalItem: {
      type: Object,
      default: () => ({}),
    },
    valueMutator: {
      type: Function,
      default: () => null,
    },
    divider: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filteredHeaders: [],
    }
  },
  computed: {
    getHeadersForFooter() {
      return this.headers.slice(1, this.headers.length)
    },
  },
  watch: {
    columnsToShow(data) {
      this.setHeaders(data)
    },
  },
  created() {
    this.setHeaders(this.columnsToShow)
  },
  methods: {
    getCellClasses(header) {
      return [
        { 'v-data-table__divider': header.divider },
        { 'fixed-col': header.fixed && header.fixed.side },
        `${header.fixed && header.fixed.side ? header.fixed.side : ''}`,
      ]
    },
    getValue(item, key) {
      const value = get(item, key)

      if (this.valueMutator) {
        return this.valueMutator({
          value,
          item,
          key,
        })
      }

      return value
    },
    setHeaders(selectedHeaders = []) {
      if (!selectedHeaders.length) {
        this.filteredHeaders = [...this.headers]
        return
      }

      const colIDs = selectedHeaders.map(selectedItem => selectedItem.value)
      this.filteredHeaders = this.headers.filter(item => {
        return !colIDs.includes(item.value)
      })
    },
  },
}
</script>

<style lang="scss">
.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 11;
}
.divider {
  border-right: 1px solid #eeeeee;
}
</style>
