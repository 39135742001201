export default [
    {
        path: '/partners-spends',
        name: 'pagePartnersSpendsList',
        meta: { title: 'partnersSpends.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_spend_view'] },
        component: () => import('@/pages/partners-spends'),
    },
    {
        path: '/partners-spends/create',
        name: 'pagePartnersSpendsCreate',
        meta: { title: 'partnersSpends.createFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_spend_view'] },
        component: () => import('@/pages/partners-spends/create'),
    },
    {
        path: '/partners-spends/:id/edit',
        name: 'pagePartnersSpendsEdit',
        meta: { title: 'partnersSpends.editFormTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['partners_spend_view'] },
        component: () => import('@/pages/partners-spends/id/edit'),
    },
]
