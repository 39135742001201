import moment from 'moment'
import {DATE_FORM_FORMAT} from "@/constants/date";
export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  custom_filter: {
    dateFrom: moment().subtract(2, 'weeks').format(DATE_FORM_FORMAT),
    dateTo: moment().format(DATE_FORM_FORMAT),
    countries: null,
    partners: null,
    advertisers: null,
    offers: null,
    landings: null,
    traffic: null
  },
  custom_order: {
    createdAt: 'DESC',
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}
