import AuthRepo from '@/repository/auth'
import { destroyTokenData, getTokenData, saveTokenData } from '@/services/token.service'
import DateConverter from '@/models/DateConverter'
const state = {
  isAuthenticated: !!getTokenData(),
  authData: null,
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  authData: state => state.authData,
}

const actions = {
  preLogin(context, payload) {
    return new Promise((resolve, reject) => {
      AuthRepo.login(payload)
      .then(({ data }) => {
        context.commit('setAuthData', { ...payload, ...data })
        resolve({ ...payload, ...data })
      })
      .catch(data => {
        reject(data)
      })
    })
  },
  seedAuthData(context) {
    const authData = JSON.parse(window.localStorage.getItem('authData'))
    if (!authData) return
    authData.fromLocalStorage = true
    context.commit('setAuthData', authData)
  },
  onLogin(context, payload) {
    return new Promise((resolve, reject) => {
      AuthRepo.login(payload)
        .then(({ data }) => {
          context.commit('setAuth', data)
          resolve(data)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onLogout(context) {
    return new Promise((resolve, reject) => {
      const tokenData = getTokenData()
      AuthRepo.logout({ tokenData })
        .then(({ data }) => {
          // location.href = '/auth/login'
          context.commit('purgeAuth')
          context.commit('ProfileModule/setProfile', null, { root: true })
          resolve(data)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onRefreshToken(context) {
    return new Promise((resolve, reject) => {
      const tokenData = getTokenData()
      const refreshToken = tokenData ? JSON.parse(tokenData).refreshToken : ''
      AuthRepo.refreshToken({ refreshToken })
        .then(({ data }) => {
          context.commit('setAuth', data)
          resolve(data)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onVerifyAuth(context) {
    if (getTokenData()) {
      if (!context.rootGetters['ProfileModule/profile']) return context.dispatch('reLoadProfile')
    } else {
      context.commit('purgeAuth')
    }
  },
  async reLoadProfile(context) {
    return new Promise(resolve => {
      context.dispatch('ProfileModule/loadProfile', null, { root: true }).then(({ profile }) => {
        DateConverter.configure()
        resolve(profile)
      })
    })
  },
}

const mutations = {
  setAuth(state, data) {
    state.isAuthenticated = true
    saveTokenData(JSON.stringify(data))
  },
  purgeAuth(state) {
    state.isAuthenticated = false
    destroyTokenData()
  },
  setAuthData(state, data) {
    if (!data.fromLocalStorage) {
      window.localStorage.setItem('authData', JSON.stringify(data))
    }
    state.authData = data
  },
  destroyAuthData(state) {
    window.localStorage.removeItem('authData')
    state.authData = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
