import moment from "moment";
import {DATE_FORM_FORMAT} from "@/constants/date";

export const defaultFilters = {
  date: moment().subtract(1, 'month').format(DATE_FORM_FORMAT),
  sortBy: ['actual_budget'],
  sortDesc: [true],
  pagination: {
    limit: 20,
    page: 1,
  },
}