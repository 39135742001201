import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import {defaultFilters} from '@/models/PartnersSpendsTable/helpers'
import partnersSpendsRepository from '@/repository/generatedRepository/partnersSpendsRepository'
import TableOptions from '@/models/TableOptions'
import {prepareFilters} from '@/helpers/filters'
import {getPrepareData} from "@/helpers/functions";

const state = {
  list: [],
  total: {},
  filters: StorageService.get(storageKeys.PARTNERS_SPENDS_TABLE_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  total: state => state.total,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.PARTNERS_SPENDS_TABLE_FILTERS, filters)

    const preparedFilters = prepareFilters({
      ...getPrepareData(filters, ['partner']),
      signal
    })

    const { data } = await partnersSpendsRepository.getTableData(preparedFilters)
    data.map(el => {
      el.dates = Object.entries(el.dates).reduce((acc, item) => {
        acc[item[0]] = item[1] !== null ? Number(item[1]).toFixed(2) : null
        return acc
      }, {})
      el.total = Object.values(el.dates).reduce((acc, item) => {
        acc += +item
        return acc
      }, 0).toFixed(2)
    })
    const total = data.reduce((acc, item) => {
      Object.entries(item.dates).forEach(date => {
        acc[date[0]] = acc[date[0]] ? acc[date[0]] : 0
        acc[date[0]] += Number(date[1])
        return acc
      })
      return acc
    }, {})
    total.total = Object.values(total).reduce((acc, item) => {
      acc += item
      return acc
    }, 0)
    for (let item of Object.keys(total)){
      total[item] = Number(total[item]).toFixed(2)
    }
    context.commit('setList', { data, total, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async updateTable(context, data){
    await partnersSpendsRepository.updateTableData(data)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.PARTNERS_SPENDS_TABLE_FILTERS)
  },
}

const mutations = {
  setList(state, { data, total, filters }) {
    state.list = data
    state.total = total
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
