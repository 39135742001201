import moment from 'moment-timezone'
import { DATE_FORMAT, TIME_FORMAT } from '@/constants/date'

export function getDateByTimeZone(data) {
  if (data) {
    return new Date().toLocaleString('en-GB', {
      timeZone: data,
      hour: '2-digit',
      minute: '2-digit',
    })
  } else {
    return ''
  }
}

export function formatDate(date, format = DATE_FORMAT) {
  return date ? moment(new Date(date)).format(format) : date
}

export function convertSecondsToFormatDate(date, format = TIME_FORMAT) {
  return moment().startOf('day').seconds(date).format(format)
}

export function convertMinutesToFormatDate(dateMinutes, dateSeconds, format = TIME_FORMAT) {
  return moment().startOf('day').minutes(dateMinutes).seconds(dateSeconds).format(format)
}

export function diffDateSecondsToFormatDate(startDate, endDate, format = TIME_FORMAT) {
  const diff = moment(startDate).diff(moment(endDate), 'seconds')
  return moment().startOf('day').seconds(Math.abs(diff)).format(format)
}
export function removeOffsetTime(date, format = TIME_FORMAT) {
  const timeInGMT6 = moment.tz(date, 'Etc/GMT-6');
  const timeInUTC = timeInGMT6.clone().utc()
  const timeInKyiv = timeInUTC.clone().tz("Europe/Kiev");
  return timeInKyiv.format(format)
}