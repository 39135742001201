<template>
  <v-badge class="ui-badge" v-bind="$attrs" v-on="$listeners" dot inline left>
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-badge>
</template>

<script>
export default {
  name: 'UiBadge',
  props: {
    status: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.ui-badge {
  .v-badge__wrapper {
    margin: 0 10px 0 0;
    .v-badge__badge {
      height: 10px;
      width: 10px;
      border-radius: 5px;
    }
  }
}
</style>
