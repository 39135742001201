import Vue from 'vue'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import '@/sass/custom/v-tooltip.scss'
VTooltip.options.autoHide = false
VTooltip.options.defaultHtml = false
VTooltip.options.defaultBoundariesElement = document.body

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
