/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

// __generator_imports__
import costsReportRoute from './costs-report'
import offerPartnersRoute from './offer-partners'
import spendsParsingLogRoute from './spends-parsing-log'
import partnersSpendsTableRoute from './partners-spends-table'
import trafficSourcesRoute from './traffic-sources'
import landingsRoute from './landings'
import budgetReportRoute from './budget-report'
import partnerReportRoute from './partner-report'
import rolesRoute from './roles'
import partnerGroupsRoute from './partner-groups'
import currenciesRoute from './currencies'
import playersReportsRoute from './players-reports'
import playersRoute from './players'
import postbacksRoute from './postbacks'
import eventsRoute from './events'
import partnersSpendsRoute from './partners-spends'
import usersRoute from './users'
import clicksRoute from './clicks'
import offerRoute from './offer'
import paymentModelsRoute from './payment-models'
import partnersRoute from './partners'
import advertisersRoute from './advertisers'
import productsRoute from './products'
import countryRoute from './country'
import generalReportRoute from './general-report'
import dashboardRoute from './dashboard'
import playersReportsApiRoute from './players-reports-api';
import cohortAnalysisRoute from "./cohort-analysis";
import settingsRoute from "@/router/generated-routes/settings";

const routes = [
  // __imported_entities__
  ...costsReportRoute,
  ...offerPartnersRoute,
  ...spendsParsingLogRoute,
  ...partnersSpendsTableRoute,
  ...trafficSourcesRoute,
  ...landingsRoute,
  ...budgetReportRoute,
  ...partnerReportRoute,
  ...rolesRoute,
  ...partnerGroupsRoute,
  ...playersReportsRoute,
  ...generalReportRoute,
  ...playersRoute,
  ...currenciesRoute,
  ...postbacksRoute,
  ...eventsRoute,
  ...partnersSpendsRoute,
  ...usersRoute,
  ...clicksRoute,
  ...offerRoute,
  ...paymentModelsRoute,
  ...partnersRoute,
  ...advertisersRoute,
  ...productsRoute,
  ...countryRoute,
  ...dashboardRoute,
  ...playersReportsApiRoute,
  ...cohortAnalysisRoute,
  ...settingsRoute,
]

export default routes
